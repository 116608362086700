import React, { useState, useEffect, useCallback } from "react";
import Header from "../../Header/Header";
import { useNavigate } from "react-router-dom";
import "../../Dashboard/Index.css";
import Sidebar from "../../Sidebar/Sidebar";
import "../../../Assets/Pages.css";
// import { CiSearch } from "react-icons/ci";
import { Viewpendingregister } from "../../../Config/api";
import { getUserDetails } from "../../../Services/Storage";
import ReactPaginate from "react-paginate";
import "../../../Assets/Pagination.css";


import nodatafound from "../../../Images/Noimagefound/Noimgfound";
import pageloader from "../../../Loader/pageloading/Pageloader";
// import Refreshimg from "../../../Images/Refreshimg/Refreshimg";
// import Action from "../../../Images/Action/action";

const IncompleteEmployee = () => {
  const [currentpage, setCurrentPage] = useState(1);
  // const [ setSearchword] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const user_details = getUserDetails();
  const navigate = useNavigate();
  const per_page = 10;



  const fetchEmployeeList = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await Viewpendingregister(
        `?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}`
      );
      setEmployees(response.data.incomplete_registration_details);
      setTotalEmployeeCount(response.data.page_context.total);
    } catch (error) {
    } finally {
      setIsLoading(false);

    }
  }, [currentpage, user_details.id]);



  useEffect(() => {
    fetchEmployeeList();
  }, [fetchEmployeeList]);



  const handleaddbutton = () => {
    navigate("/addemployee");
  };
  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };


  const handleNavigation = (keyValue, name, id) => {
    switch (keyValue) {
      case 'staff_pending':
        navigate('/basicdetails', { state: { name: name, user_id: id } })
        break
      case 'payment_pending':
        navigate('/addpayment', { state: { name: name, user_id: id } });
        break;
      case 'salary_pending':
        navigate('/salarydetails', { state: { name: name, user_id: id } });
        break;
      default:
        navigate('/default-page');

    }

  }
  return (
    <>
      <div className="index-container">
        <Header />
        <div className="index-content-parent">
          <div className="index-content">
            <Sidebar />
            <div className="index-content-sub">
              <div className="index-main">
                <div className="pages-subheader">


                  <div className="pages-subheader-sub-1">
                    <p> Employees</p>
                    <span className="header-search-add-parent">

                      {/* <div className="pages-serach-view">
                            <CiSearch className="page-search-icon" />
                            <input
                              onChange={handleSearch}
                              className="pages-serach"
                              placeholder="Search Employee"
                              type="search"
                            />
                          </div> */}
                      {/* <button
                            className="pages-addempbutton"
                            onClick={handleaddbutton}
                          >
                            Resume
                          </button> */}
                      <button
                        className="pages-addempbutton"
                        onClick={handleaddbutton}
                      >
                        Add Employee
                      </button>
                    </span>
                  </div>


                  <div className="pages-line"></div>
                </div>

                <div className="pages-maincontent">
                  {isloading ? (
                    <div className="loader-container">
                      <img src={pageloader.page_loader} alt="Loading..." />
                    </div>
                  ) : (
                    <table className="pages-table">
                      <thead>
                        <tr>

                          <th></th>
                          <th>Name</th>
                          <th> Email</th>
                          <th>Phone</th>
                          <th></th>

                        </tr>
                      </thead>
                      <tbody>
                        {employees.length > 0 ? (
                          employees.map((employee, index) => (
                            <tr key={employee.register_id}>

                              <td></td>
                              <td>
                                {employee.register_details.name}
                              </td>
                              <td>{employee.register_details.email}</td>
                              <td>{employee.register_details.phone}</td>
                              <td>
                                <button className='employee-regadd-button' onClick={() => handleNavigation(employee.key_value, employee.register_details.name, employee.register_id)}>
                                  Add
                                </button>

                              </td>

                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="no-data-table">
                              <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )
                  }
                  <div style={{ float: "right" }} id="pagination_link"></div>
                  <div style={{ padding: "20px" }}>
                    {totalEmployeeCount > per_page ? (
                      <>
                        <ReactPaginate
                          pageCount={Math.ceil(totalEmployeeCount / per_page)}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          onPageChange={currentPageNo}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </>
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default IncompleteEmployee;
