import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const departmentTlSlice = createSlice({
    name: 'department_tl',
    initialState,
    reducers: {
        addDepartmentTl(state, action) {
            state.push(action.payload)

        },
        setDepartmentTL(state, action) {
            return action.payload;
        },
        updateDepartmentTL(state, action) {
            const updatedComponent = action.payload;
            return state.map((component) =>
                component.row_id === updatedComponent.row_id
                    ? { ...component, ...updatedComponent }
                    : component
            )
        },
        deleteDepartmenttl(state, action) {
            const componentId = action.payload;
            return state.filter((component) => component.row_id !== componentId);
        },
    }
})

export const { addDepartmentTl, setDepartmentTL, updateDepartmentTL, deleteDepartmenttl } = departmentTlSlice.actions;
export default departmentTlSlice.reducer;