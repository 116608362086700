import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../../Header/Header'
import '../../Style/Addemp.css'
import './Vieweditdetails.css'
import { CiBadgeDollar, CiBank, CiCalendar, CiCreditCard1, CiCreditCard2, CiEdit, CiLocationOn, CiMail, CiPhone, CiUser, CiViewList } from 'react-icons/ci'
import Sidebar from '../../../Sidebar/Sidebar'
import api_backend from '../../../../Config/api'
import { useSelector } from 'react-redux'
import userdemoimg from '../../../../Images/Pagesimg/userdemo'

const Vieweditdetails = () => {
  //const location = useLocation();
  //const { user_id } = location.state || {};
  const employeeDetails1 = useSelector((state) => state.employees);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  useEffect(() => {
    if (employeeDetails1 && employeeDetails1.employees.length > 0) {
      setEmployeeDetails(employeeDetails1.employees[0]);
    }
  }, [employeeDetails1]);
  const navigate = useNavigate();
  const editbasic = () => {
    // const basicInfo = {
    //   emp_name: employeeDetails.register_details.name,
    //   work_details: employeeDetails.work_details,
    //   department_name: employeeDetails.department_name,
    //   designation_name: employeeDetails.designation_name,
    //   work_location: employeeDetails.work_location,
    // };
    navigate('/editbasicinfo')
  }

  const editpersonal = () => {
    navigate('/editpersonalinfo')
  }

  const editpayment = () => {
    navigate('/editpaymentinfo')
  }
  const handleaddemploye = () => {
    navigate('/addemployee')
  }

  const overview_view = () => {
    // navigate('/vieweditdetails', { state: { user_id } });
  }

  const salary_view = () => {
    navigate('/viewsalarydetails');
  }

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          {employeeDetails ?
            (
              <>
                <div className='index-content-sub'>
                  <div className='index-main'>
                    <div className='addemp-subheader'>
                      <div className='addemp-subheader-sub'>
                        <div className='vieweditdet-subheader'>
                          <p> {employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.name : "N/A"} </p>
                          <div className='vieweditdet-top-dropdown'>
                            <button onClick={handleaddemploye}>Add Employee</button>
                          </div>
                        </div>
                        <div className='addemp-subheader2'>
                          <div className="vieweditdet-stepper">
                            <div className="vieweditdet-step-indicator">
                              <span className='vieweditdet-redirect-link' onClick={overview_view}>
                                <div className='vieweditdet-step vieweditdet-selected'>
                                  Overview
                                </div>
                              </span>
                              <span className='vieweditdet-redirect-link2' onClick={salary_view}>
                                <div className='vieweditdet-step'>
                                  Salary Details
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='addemp-line'></div>
                    <div className='addemp-maincontent-sub'>
                      <div className='addemp-form emp-edit'>
                        {/* <div className='addemp-form-sub'> */}
                        {/* <div className='addemp-form-sub-main'> */}
                        <div className="vieweditdet-left-side">
                          <div className='vieweditdet-left-side-sub'>
                            <div className='vieweditdet-card vieweditdet-full-card'>
                              <div className='vieweditdet-edit-icon' onClick={editbasic}>
                                <CiEdit />
                              </div>
                              <div className='vieweditdet-profile-edit'>
                                {employeeDetails.work_details.staff_pic ?
                                  (<img src={`${api_backend.BACKEND_API}${employeeDetails.work_details.staff_pic}`} alt="Employee" />
                                  )
                                  :
                                  (<img src={userdemoimg.demo_img} alt='img' />)

                                }
                              </div>
                              <div className='vieweditdet-leftside-name'>
                                <h4>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.name : "N/A"}</h4>
                              </div>
                              <div className='vieweditdet-line'></div>
                              <div className='vieweditdet-leftcontent-view'>
                                <p className='vieweditdet-heading-p-left'>BASIC DETAILS:</p>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiMail />
                                  </div>
                                  <p>{employeeDetails && employeeDetails.work_details ? employeeDetails.work_details.work_email : "N/A"}</p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiPhone />
                                  </div>
                                  <p>{employeeDetails && employeeDetails.work_details ? employeeDetails.work_details.emergency_contact : "N/A"}</p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiUser />
                                  </div>
                                  <p>{employeeDetails && employeeDetails.work_details ? employeeDetails.work_details.gender : "N/A"}</p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiCalendar />
                                  </div>
                                  <p>{employeeDetails ? employeeDetails.department_name : "N/A"}
                                  </p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiViewList />
                                  </div>
                                  <p>{employeeDetails ? employeeDetails.designation_name : "N/A"}</p>
                                </span>
                                <span className='vieweditdet-details-left'>
                                  <div className='vieweditdet-details-left-icon'>
                                    <CiLocationOn />
                                  </div>
                                  <p>Marthadam</p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vieweditdet-right-side">
                          <div className='vieweditdet-card1'>
                            <div className='vieweditdet-edit-icon' onClick={editpersonal}>
                              <CiEdit />
                            </div>
                            <p className='vieweditdet-heading-p-left'>PERSONAL INFO:</p>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiUser />
                              </div>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.name : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiPhone />
                              </div>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.phone : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiMail />
                              </div>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.email : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiLocationOn />
                              </div>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.address_line1 : "N/A"}</p>
                            </span>

                          </div>
                          <div className='vieweditdet-card1'>
                            <div className='vieweditdet-edit-icon' onClick={editpayment}>
                              <CiEdit />
                            </div>
                            <p className='vieweditdet-heading-p-left'>PAYMENT:</p>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiBank />
                              </div>
                              <p>Bank :  {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.bank_name : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiCreditCard1 />
                              </div>
                              <p> Aadhar :  {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.aadhar_num : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiCreditCard2 />
                              </div>
                              <p> Pan : {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.pan_num : "N/A"}</p>
                            </span>
                            <span className='vieweditdet-details-left'>
                              <div className='vieweditdet-details-left-icon'>
                                <CiBadgeDollar />
                              </div>
                              <p>UAN :  {employeeDetails && employeeDetails.payment_details ? employeeDetails.payment_details.UAN : "N/A"}</p>
                            </span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </>
            )
            :
            (
              <></>
            )
          }

        </div>
      </div>
    </div>
  )
}

export default Vieweditdetails
