import React, { useState } from 'react';
import './Registeration.css';
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Otpverifyregister, Registration } from '../../Config/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../Services/Authentication';
import Register_img from '../../Images/Register/Registerpage';
import Title from '../../JsonData/Tittle';
import { storeUserDetails, storeUserToken } from '../../Services/Storage';
import { Link } from 'react-router-dom';
import { decryptOTP } from '../Decript';

const Registeration = () => {

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [split, setsplit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [otpsend, setotpsend] = useState(false);
  const [otp, setOtp] = useState(new Array(5).fill(""));
  const [timer, setTimer] = useState(300);  // Timer state (60 seconds)
  const [isResendDisabled, setIsResendDisabled] = useState(true); // Disable resend initially
  const [resdloader, resdsetloader] = useState(false);
  const [otpError, setOtpError] = useState('');  // Add a state for OTP error
  const [enterotp, setenterotp] = useState("");
  const [loderstp1, setloaderstp1] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address_line1: '',
    address_line2: '',
    password: '',
    city: '',
    state: '',
    pincode: '',
    role: "Company",
    user_mr_ms: "Mr",
  });

  const startTimer = () => {
    setTimer(300);  // Reset timer to 5 minutes (300 seconds)

    setIsResendDisabled(true);  // Disable the resend button initially
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setIsResendDisabled(false);  // Enable resend button once timer is 0
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        // Move to the next box if a digit is entered
        document.getElementById(`otp-${index + 1}`).focus();
      } else if (value === "" && index > 0 && newOtp[index] === "") {
        // Move to the previous box if cleared and the current box is empty
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };
  const handleSubmit = async () => {
    const newErrors = {};
    if (formData.address_line1.trim() === '') {
      newErrors.address_line1 = 'Address Line1 is required';
    } if (formData.address_line2.trim() === '') {
      newErrors.address_line2 = 'Address Line2 is required';
    } if (formData.city.trim() === '') {
      newErrors.city = 'City is required';
    } if (formData.state.trim() === '') {
      newErrors.state = 'State is required';
    }
    if (formData.pincode.trim() === '') {
      newErrors.pincode = 'Pincode is required';
    } else if (!/^\d+$/.test(formData.pincode)) {
      newErrors.pincode = 'Pincode must contain only digits';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await Registration(formData);
        storeUserToken(response.token);
        storeUserDetails(response.user_details);
        if (response) {
          toast.success('Signup successful!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          });
          setTimeout(() => {
            navigate('/compdetails');
          }, 1000); // Navigate after 2 seconds (2000 ms)
        }
      }
      catch (error) {
        if (error.response) {
          const newErrors = {};
          newErrors.response_error = error.response.data.data;
          setErrors(newErrors);
        }
        else {
          toast.error('Signup failed. Please try again.', {
            position: "top-center",  // Use a string directly for the position
            autoClose: 2000,
            closeButton: false,
          });
          const newErrors = {
            response_error: [],
          }; for (const key in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data.errors[key]}`);
            }
          }
          setErrors(newErrors);
          setFormData((state) => ({
            ...state,
          }));
        }
      }
      finally {
        setLoading(false);
      }
    }
  }

  if (isAuthenticated()) {
    return <Navigate to="/dashboard" />
  }

  const handlenext = async () => {
    const newErrors = { ...errors }; // Retain any existing errors

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
      newErrors.name = "Name must contain only alphabetic characters and spaces";
    } else {
      delete newErrors.name; // Clear the error if it is corrected
    }

    if (formData.phone.trim() === "") {
      newErrors.phone = "Phone is required";
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone must contain only digits";
    } else if (formData.phone.length < 10 || formData.phone.length > 13) {
      newErrors.phone = "Phone must be between 10 to 13 digits";
    } else {
      delete newErrors.phone;
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    } else {
      delete newErrors.email;
    }

    if (formData.password.trim() === "") {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      newErrors.password = "The password must contain 8 Characters.";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(formData.password)
    ) {
      newErrors.password =
        "The password must contain digits, uppercase letters, and lowercase letters.";
    } else {
      delete newErrors.password;
    }

    // Set the new errors (or clear if there are none)
    setErrors(newErrors);

    // Proceed to the next step only if there are no errors
    if (Object.keys(newErrors).length === 0) {
      const data = {
        'name': formData.name,
        'email': formData.email,
      }
      try {
        setloaderstp1(true)
        const response = await Otpverifyregister(data)
        if (response) {
          setenterotp(response.register_otp_data.register_otp_code)
          setotpsend(true);
          startTimer();  // Start the timer when OTP is sent

        }
      } catch {

      } finally {
        setloaderstp1(false)
      }
    }
  };

  const handleprev = () => {
    const newErrors = { ...errors };

    delete newErrors.address_line1;
    delete newErrors.address_line2;
    delete newErrors.city;
    delete newErrors.state;
    delete newErrors.pincode;
    setErrors(newErrors);
    setenterotp("");
    setotpsend(false);
    setsplit(true);

  }
  const handleprevotp = () => {
    setenterotp("");
    setotpsend(false);
    setOtp(new Array(5).fill("")); // Clear OTP fields

    setOtpError('')

  }
  const handleOtpSubmit = () => {
    const enteredOtp = otp.join("");
    const decript = decryptOTP(enterotp);
    if (enteredOtp.length < 5) {
      setOtpError("Please enter a valid 5-digit OTP.");
    } else if (enteredOtp === decript) {
      setsplit(false);
      setOtp(new Array(5).fill("")); // Clear OTP fields
      setOtpError('')


    } else {
      setOtpError("Entered OTP is incorrect.");
    }
  };
  const handleresendotp = async () => {
    const data = {
      'name': formData.name,
      'email': formData.email,
    }
    try {
      resdsetloader(true)
      const response = await Otpverifyregister(data)
      if (response) {
        startTimer();  // Start the timer when OTP is sent
        setenterotp(response.register_otp_data.register_otp_code)

      }
    } catch {

    } finally {
      resdsetloader(false)
    }
  };
  return (
    <div className='registeration-container'>
      <div className='registeration-container-sub'>
        <div className='registeration-content'>
          <div className='register-split'>
            {/* <p className='para-reg'>Create an account to get started on your journey with us  :)</p> */}

            <div className='register-img-view'>
              <div className='register-img-view-sub'>

                <img src={Register_img.registerimg} alt='img' />

              </div>
            </div>
          </div>
          <div className='register-split-1'>
            <div className='registeration-content-sub'>
              <div className='registeration-main'>
                {/* <div className='registeration-logo'>
                <img className="registeration-logo-img" src={logo.logo_img} alt="Login" />
              </div> */}
                <div className='registration-sub'>

                  <div className='registeration-form-fields'>
                    <p>Start Your Journey :)</p>
                    <h2 className='reg-heading'>Sign Up to Staffin</h2>
                    {!otpsend ? (
                      <>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>

                            <label htmlFor='first-input'> Name <span className='registeration-mandatory'>*</span></label>
                            <span className='select-input'>
                              <select className='select_mr_mrs' value={formData.user_mr_ms} onChange={handleInputChange} name='user_mr_ms'>
                                {Title.title.map((tittle, index) => (
                                  <option key={index}>
                                    {tittle}

                                  </option>
                                )
                                )
                                };
                              </select>
                              <input id='first-input' type='text' onChange={handleInputChange} name='name' value={formData.name} className='registeration-input-field' />
                            </span>

                            {errors.name && (
                              <div className="registeration-error-view">{errors.name}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='third-input'>Phone <span className='registeration-mandatory'>*</span></label>
                            <input id='third-input' type='text' onChange={handleInputChange} name='phone' value={formData.phone} className='registeration-input-field' />
                            {errors.phone && (
                              <div className="registeration-error-view">{errors.phone}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='fifth-input'>Email <span className='registeration-mandatory'>*</span></label>
                            <input id='fifth-input' type='text' onChange={handleInputChange} name='email' value={formData.email} className='registeration-input-field' />
                            {errors.email && (
                              <div className="registeration-error-view">{errors.email}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='seventh-input'>Password <span className='registeration-mandatory'>*</span></label>
                            <div className='registeration-password-container'>
                              <input id='seventh-input' type={showPassword ? "text" : "password"} onChange={handleInputChange} name='password' value={formData.password} className='registeration-input-field' />
                              <FontAwesomeIcon className="registeration-password-icon" icon={showPassword ? faEye : faEyeSlash} onClick={togglePasswordVisibility} />

                            </div>
                            {errors.password && (
                              <div className="registeration-error-view">{errors.password}</div>
                            )}
                          </div>


                        </div>
                        <div className='registeration-nextsplit'>
                          <button className='registeration-button' disabled={loderstp1} onClick={handlenext}>
                            {loderstp1 ? <span className="spinner"></span> : 'Submit'}

                          </button>
                        </div>
                      </>
                    ) :


                      split ? (
                        <>
                          <div className='registeration-otp-content'>
                            {/* <h3>Enter OTP</h3> */}
                            <p>We've sent an OTP to your email. Please enter it below.</p>
                          </div>
                          <div className='registeration-otpinput-view'>
                            <div className='registeration-inputfd otp-input-box'>
                              {otp.map((digit, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  maxLength="1"
                                  id={`otp-${index}`}
                                  value={digit}
                                  onChange={(e) => handleOtpChange(e, index)}
                                  className="otp-box"
                                />
                              ))}

                            </div>
                          </div>

                          <div className='resend-otp-cont'>
                            {isResendDisabled ?
                              <p style={{ color: 'cornflowerblue' }}>
                                Resend ({formatTime(timer)})
                              </p>
                              : <p style={{
                                color: 'cornflowerblue',
                                cursor: resdloader ? "default" : "pointer",
                                pointerEvents: resdloader ? "none" : "auto"  // Disable click when loading
                              }}
                                onClick={handleresendotp}
                              >
                                {resdloader ? (
                                  <span className="spinner"></span> // Show spinner when loading
                                ) : (
                                  'Resend'
                                )}
                              </p>}
                          </div>
                          {otpError && (
                            <div className="registeration-error-view" style={{ textAlign: "center" }}>{otpError}</div>
                          )}

                          <div className='registeration-otp-buttons'>
                            <button onClick={handleprevotp}>
                              Back
                            </button>
                            <button onClick={handleOtpSubmit}>
                              Verify
                            </button>
                          </div>
                        </>)
                        : (
                          <div>
                            <div className='registeration-input-row'>

                              <div className='registeration-input-group'>
                                <label htmlFor='second-input'>Address Line 1 <span className='registeration-mandatory'>*</span></label>
                                <input id='second-input' type='text' onChange={handleInputChange} name='address_line1' value={formData.address_line1} className='registeration-input-field input-cls-full' />
                                {errors.address_line1 && (
                                  <div className="registeration-error-view">{errors.address_line1}</div>
                                )}
                              </div>


                            </div>

                            <div className='registeration-input-row'>

                              <div className='registeration-input-group'>
                                <label htmlFor='fourth-input'>Address Line 2 <span className='registeration-mandatory'>*</span></label>
                                <input id='fourth-input' type='text' onChange={handleInputChange} name='address_line2' value={formData.address_line2} className='registeration-input-field' />
                                {errors.address_line2 && (
                                  <div className="registeration-error-view">{errors.address_line2}</div>
                                )}
                              </div>
                            </div>
                            <div className='registeration-input-row'>

                              <div className='registeration-input-group'>
                                <label htmlFor='sixth-input'>City <span className='registeration-mandatory'>*</span></label>
                                <input id='sixth-input' type='text' onChange={handleInputChange} name='city' value={formData.city} className='registeration-input-field' />
                                {errors.city && (
                                  <div className="registeration-error-view">{errors.city}</div>
                                )}
                              </div>

                            </div>
                            <div className='registeration-input-row'>


                              <div className='registeration-input-group'>
                                <label htmlFor='eighth-input'>State <span className='registeration-mandatory'>*</span></label>
                                <input id='eighth-input' type='text' onChange={handleInputChange} name='state' value={formData.state} className='registeration-input-field' />
                                {errors.state && (
                                  <div className="registeration-error-view">{errors.state}</div>
                                )}
                              </div>
                              <div className='registeration-input-group'>
                                <label htmlFor='tenth-input'>Pincode <span className='registeration-mandatory'>*</span></label>
                                <input id='tenth-input' type='text' onChange={handleInputChange} name='pincode' value={formData.pincode} className='registeration-input-field' />
                                {errors.pincode && (
                                  <div className="registeration-error-view">{errors.pincode}</div>
                                )}
                              </div>

                            </div>
                            <div >
                              {errors.response_error && (
                                <div className="registeration-error-view">{errors.response_error}</div>
                              )}
                              <div className='registeration-button-div'>

                                <button onClick={handleprev} className='registeration-button'>
                                  Prev
                                </button>

                                <button className='registeration-button' onClick={handleSubmit} disabled={loading}>
                                  {loading ? <span className="spinner"></span> : 'Register'}
                                </button>
                              </div>
                            </div>
                          </div>
                        )

                    }

                    <div className=''>
                      <p className='reg-signup'>Have a Staffin account?
                        <Link to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
};

export default Registeration;
