import React, { useState, useEffect, useRef } from "react";
//import "../../AddEmployees/Basicdetails/Basicdetails.css";
import Sidebar from "../../../Sidebar/Sidebar";
import Header from "../../../Header/Header";
import { FaCircleUser } from "react-icons/fa6";
import { convertTo12HourFormat } from "../../../Component/TimeFormat";
import api_backend, { StaffAllDetailsApi, UpdateEmployeeBasicApi, ViewEditEmployeeApi } from "../../../../Config/api";
import userdemoimg from "../../../../Images/Pagesimg/userdemo";
import 'react-time-picker/dist/TimePicker.css';
import { toast } from 'react-toastify';
import { getStaffDetails, getUserDetails, storeStaffDetails } from "../../../../Services/Storage";

const Edituserbasicinfo = () => {
  const [Empname, setEmpName] = useState("");
  const [basicInfo, setBasicInfo] = useState(null);
  const user_details = getUserDetails();


  //   useEffect(() => {
  //     if (basicInfo1 && basicInfo1.employees.length > 0) {
  //       setBasicInfo(basicInfo1.employees[0]);
  //       setEmpName(basicInfo1.employees[0].register_details.name);
  //     }

  //   }, [basicInfo1]);
  // useEffect(() => {
  //   const staffdetails = getStaffDetails();

  //   setBasicInfo(staffdetails);


  // }, []);
  useEffect(() => {
    const fetchuserdetails = async () => {
      const staffdetails = getStaffDetails();
      console.log(staffdetails.register_details.id)
      try {
        const response = await ViewEditEmployeeApi(staffdetails.register_details.id)
        setBasicInfo(response.data.get_staff_details[0])
        console.log("asasasa", response.data.get_staff_details[0].work_details)
      } catch {

      }
    }
    fetchuserdetails();
    // if (employeeDetails1 && employeeDetails1.employees.length > 0) {
    //   setEmployeeDetails(employeeDetails1.employees[0]);
    // }
  }, []);

  const fetchStaffAllDetails = async () => {
    if (user_details.role === 'staff') {
      try {
        const response = await StaffAllDetailsApi(`?register_id=${user_details.id}&admin_id=${user_details.user_id}`);
        storeStaffDetails(response.data.staff_all_details);
      } catch (error) {
      }
    }
  }

  const [selectedimg, setselectedimg] = useState(null);

  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({});
  const [timefrom, setTimefrom] = useState("");
  const [timeto, setTimeto] = useState("");
  const [permishr, setpermishr] = useState("")

  const [updateBasicInfo, setUpdateBasicInfo] = useState({
    emp_name: " ",
    work_email: " ",
    father_name: " ",
    dob: " ",
    gender: " ",
    blood_group: " ",
    emergency_contact: " ",
    doj: " ",
    // from_time           : " ",
    // to_time             : " ",
    work_location: " ",
    designation: " ",
    department: " ",
    depart_tl: " ",
    depart_head: " ",
    emp_id: " ",
    work_details: " ",
    permission_hr: null,
    staff_pic: " ",
    eligible_leave: [],
    work_from: null,
    work_to: null,
  });
  useEffect(() => {
    if (basicInfo) {
      console.log(basicInfo.staff_details)
      setUpdateBasicInfo({
        emp_name: basicInfo.register_details.name || '',
        work_email: basicInfo.work_details.work_email || '',
        father_name: basicInfo.work_details.father_name || '',
        dob: basicInfo.work_details.dob || '',
        gender: basicInfo.work_details.gender || '',
        blood_group: basicInfo.work_details.blood_group || '',
        emergency_contact: basicInfo.work_details.emergency_contact || '',
        doj: basicInfo.work_details.doj || '',
        // from_time           : basicInfo.work_details.work_from,
        // to_time             : basicInfo.work_details.work_to,
        work_location: basicInfo.work_details.work_location || '',
        designation: basicInfo.work_details.designation || '',
        company: basicInfo.work_details.company || '',

        department: basicInfo.work_details.department || '',
        depart_tl: basicInfo.work_details.depart_tl || '',
        depart_head: basicInfo.work_details.depart_head || '',
        emp_id: basicInfo.work_details.emp_id || '',
        staff_pic: basicInfo.work_details.staff_pic || '',
         eligible_leave: basicInfo.work_details.eligible_leave || [],
        permission_hr: basicInfo.work_details.permission_hr || '',
        work_from: basicInfo.work_details.work_from || '',
        work_to: basicInfo.work_details.work_to || '',
      });
      setTimefrom(basicInfo.work_details.work_from);
      setTimeto(basicInfo.work_details.work_to);
      setpermishr(basicInfo.work_details.permission_hr);
      setEmpName(basicInfo.register_details.name)
      // if(basicInfo.work_details.staff_pic){
      //   setselectedimg(basicInfo.work_details.staff_pic);
      // }
    }
  }, [basicInfo])
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'time') {
      setUpdateBasicInfo((state) => ({
        ...state, [name]: convertTo12HourFormat(value),
      }));
    }
    else if (event.target.type === "file") {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        setUpdateBasicInfo((state) => ({
          ...state,
          [name]: file,
        }));
        setselectedimg(URL.createObjectURL(file)); // Set the selected image for preview

      } else {
        setselectedimg(null);

      }
    }
    else {
      setUpdateBasicInfo((state) => ({
        ...state, [name]: value,
      }));
    }
  }

  const handleSubmit = async () => {


    let newErrors = {};
    if (updateBasicInfo.emp_id.trim() === "") {
      newErrors.emp_id = "Employee Id is Required";
    }
    if (updateBasicInfo.work_email.trim() === "") {
      newErrors.work_email = "Employee Email is Required";
    }
    if (updateBasicInfo.father_name.trim === "") {
      newErrors.father_name = "Father name is Required";
    }
    if (updateBasicInfo.dob.trim() === "") {
      newErrors.dob = "Date of Birth is Required";
    }
    if (updateBasicInfo.gender.trim() === "") {
      newErrors.gender = "Please Select Gender";
    }
    if (updateBasicInfo.blood_group.trim() === "") {
      newErrors.blood_group = "Blood Group is Required";
    }
    const emergencyContact = String(updateBasicInfo.emergency_contact).trim();

    if (emergencyContact.trim() === "") {
      newErrors.emergency_contact = "Emergency Contact No is Required";
    }
    if (updateBasicInfo.doj.trim() === "") {
      newErrors.doj = "Date of Joining is Required"
    }
    if (updateBasicInfo.work_from === null) {
      newErrors.work_from = "Work From is Required";
    }
    if (updateBasicInfo.work_to === null) {
      newErrors.work_to = "Work To is Required"
    }
    const worklocat = String(updateBasicInfo.work_location).trim();
    console.log("locatttt",worklocat)

    if (worklocat === "") {
      newErrors.work_location = "Select Work Location"
    }
    const design = String(updateBasicInfo.designation).trim();
    if (design === "") {
      newErrors.designation = "Select Designation"
    }
    const depart = String(updateBasicInfo.department).trim();
    if (depart === "") {
      newErrors.department = "Select Department ";
    }
    // const dep_hed = String(updateBasicInfo.depart_head).trim();
    // if (dep_hed === "") {
    //   newErrors.depart_head = "Select Department Head"
    // }
    // const dep_tl = String(updateBasicInfo.depart_tl).trim();
    // if (dep_tl === "") {
    //   newErrors.depart_tl = "Select Department TL"
    // }
    if (updateBasicInfo.permission_hr === null) {
      newErrors.permission_hr = "Permission Hour is Required"
    }
    if (updateBasicInfo.eligible_leave.length === 0) {
      newErrors.eligible_leave = "Select Eligible Leave"
    }
    // if (!updateBasicInfo.staff_pic) {
    //   newErrors.staff_pic = "Profile Image is Required"
    // }

    setErrors(newErrors);
    // console.log(updateBasicInfo ,updateBasicInfo.eligible_leave)

    if (Object.keys(newErrors).length === 0) {
      try {

        setloading(true)
        const data = {
          ...updateBasicInfo,
          eligible_leave: JSON.stringify(updateBasicInfo.eligible_leave), // Convert only when sending
        };

        if (selectedimg) {
          data.staff_pic = updateBasicInfo.staff_pic;
        } else {
          delete data.staff_pic;
        }
        const response = await UpdateEmployeeBasicApi(basicInfo.work_details.id, data);
        if (response) {
          toast.success('Basic Details updated successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          // setselectedimg(null);
          fetchStaffAllDetails();
        }

      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              // Add each error message to the newErrors object with the field name as the key
              newErrors[key] = errorData[key];
            }
          }


          // Set the errors using setErrors
          setErrors(newErrors);

          // setBasicInfo((state) => ({
          //   ...state,
          // }));
        } else {
          toast.error('Failed.Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],

          };

          setErrors(newErrors);
          setUpdateBasicInfo((state) => ({
            ...state,
          }));
        }
      }
      finally {
        setloading(false)
      }
    }
  }
  const fileinput = useRef(null);
  const handleuploadimg = () => {
    fileinput.current.click();
  }



console.log("issac",basicInfo)

  // Function to convert 24-hour format to 12-hour format with AM/PM
  const convertTo12HourFormats = (time24) => {
    let [hours, minutes] = time24.split(":");
    hours = parseInt(hours, 10);

    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;  // Convert 24-hour to 12-hour format (handle 00 as 12)

    return `${hours}:${minutes} ${period}`;
  };
  const displayTimefrom = convertTo12HourFormats(timefrom);
  const displayTimeto = convertTo12HourFormats(timeto);

  const viewpermissionhr = (time) => {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    return `${hours}:${minutes}`
  }

  const viewpermis = viewpermissionhr(permishr)


  return (
    <div className="index-container">
      <Header />
      <div className="index-content-parent">
        <div className="index-content">
          <Sidebar />
          {basicInfo ? (
            <>
              <div className="index-content-sub">
                <div className="index-main">
                  <div className="addemp-subheader">
                    <div className="addemp-subheader-sub">
                      {/* <div className="addemp-subheader1">
<p>Edit Basic Information</p>
</div> */}
                      <div className="addemp-subheader2">
                        <div className="editbasic-stepper">
                          <FaCircleUser className="editbasic-icon-large" />
                          <p>{Empname}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addemp-line"></div>

                  <div className="addemp-maincontent-sub">
                    <div className="addemp-form form-padding">
                      <div className="addemp-form-sub">
                        <div className='addemp-form-sub-main'>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-imgemp">
                              <div className="emppersonalinfo-input-imgemp-sub">

                                <div className="emppersonalinfo-input-imgemp-main" onClick={handleuploadimg}>
                                  {selectedimg ? (<img src={selectedimg} alt="demo" />
                                  )
                                    : updateBasicInfo.staff_pic ? (
                                      <img src={`${api_backend.BACKEND_API}${updateBasicInfo.staff_pic}`} alt="Employee" />

                                    ) :
                                      (<img src={userdemoimg.demo_img} alt="demo" />
                                      )
                                  }
                                  <input type="file" style={{ display: 'none' }} name="staff_pic" ref={fileinput} onChange={handleInputChange} />

                                </div>

                              </div>
                              {/* <label className='label-fields'>Upload Image </label> */}

                              {/* {errors.staff_pic && (<div className='error-message-view'>{errors.staff_pic}</div>)} */}

                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Employee Name
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                placeholder="Enter employee name"
                                type="text"
                                name="emp_name"
                                onChange={handleInputChange}
                                value={updateBasicInfo.emp_name}
                                disabled
                              />
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Employee ID
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                placeholder="Enter employee id"
                                type="text"
                                name="emp_id"
                                onChange={handleInputChange}
                                value={updateBasicInfo.emp_id}
                                disabled
                              />
                              {errors.emp_id && (<div className="error-message-view">{errors.emp_id}</div>)}
                            </div>
                          </div>

                          <div className='basicdetails-input-col'>
                            <div className='basicdetails-col-view'>
                              <label>Work Email <span className='emppersonalinfo-mandatory'>*</span></label>
                              <input className='emppersonalinfo-inputfield-col' placeholder='abc@xyz.com' disabled type='text' value={updateBasicInfo.work_email} name="work_email" onChange={handleInputChange} />
                            </div>
                            {errors.work_email && (<div className="error-message-view">{errors.work_email}</div>)}
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Father Name
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                name="father_name"
                                value={updateBasicInfo.father_name}
                                onChange={handleInputChange}
                              />
                              {errors.father_name && (<div className="error-message-view">{errors.father_name}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Date Of Birth
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="date"
                                name="dob"
                                value={updateBasicInfo.dob}
                                onChange={handleInputChange}
                              />
                              {errors.dob && (<div className="error-message-view">{errors.dob}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Gender
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input className="emppersonalinfo-input-field3" value={updateBasicInfo.gender
                              } disabled />

                              {errors.gender && (<div className="error-message-view">{errors.gender}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Blood Group
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                name="blood_group"
                                value={updateBasicInfo.blood_group}
                                onChange={handleInputChange}
                              />
                              {errors.blood_group && (<div className="error-message-view">{errors.blood_group}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Emergency Contact No
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="text"
                                name="emergency_contact"
                                value={updateBasicInfo.emergency_contact}
                                onChange={handleInputChange}
                              />
                              {errors.emergencyContact && (<div className="error-message-view">{errors.emergency_contact}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Date Of Joining
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              <input
                                className="emppersonalinfo-input-field"
                                type="date"
                                name="doj"
                                disabled
                                value={updateBasicInfo.doj}
                                onChange={handleInputChange}
                              />
                              {errors.doj && (<div className="error-message-view">{errors.doj}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Permission Hour<span className="emppersonalinfo-mandatory">
                                  *
                                </span>
                              </label>
                              {/* <TimePicker
                                onChange={handlechangeper}
                                value={updateBasicInfo.permission_hr}
                                className="time-picker"
                                format="HH:mm"
                                disabled
                                clockIcon={null}
                              /> */}
                              <input className="emppersonalinfo-input-field3" value={viewpermis} disabled />

                              {errors.permission_hr && (<div className="error-message-view">{errors.permission_hr}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Leave Eligible
                                <span className="emppersonalinfo-mandatory">
                                  *
                                </span>
                              </label>

                              <select className="emppersonalinfo-input-field3">
                                <option value="" disabled selected>
                                  View Leave's
                                </option>

                                {basicInfo.eligible_leave_details.map((leaveview, index) => (
                                  <option key={index} disabled> {leaveview.leave_name}</option>

                                )
                                )

                                }
                              </select>
                              {errors.eligible_leave && (<div className="error-message-view">{errors.eligible_leave}</div>)}
                            </div>
                          </div>
                          <p className='emp-work-p'>Work Timing:</p>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                From Time
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>
                              {/* <TimePicker
                                onChange={handlefromtimechange}
                                className="time-picker"
                                value={updateBasicInfo.work_from}
                                disabled
                              /> */}
                              <input className="emppersonalinfo-input-field3" value={displayTimefrom} disabled />

                              {errors.work_from && (<div className="error-message-view">{errors.work_from}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                To Time
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>

                              {/* <TimePicker
                                onChange={handletotimechange}
                                value={updateBasicInfo.work_to}
                                className="time-picker"
                                disabled
                              /> */}
                              <input className="emppersonalinfo-input-field3" value={displayTimeto} disabled />
                              {errors.work_to && (<div className="error-message-view">{errors.work_to}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-input-row">
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Work Location
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>

                              <input className="emppersonalinfo-input-field3" value={basicInfo.work_location.company_name} disabled />
                              {errors.work_location && (<div className="error-message-view">{errors.work_location}</div>)}
                            </div>
                            <div className="emppersonalinfo-input-group">
                              <label>
                                Designation
                                <span className="emppersonalinfo-mandatory">*</span>
                              </label>

                              <input className="emppersonalinfo-input-field3" value={basicInfo.designation_name} disabled />

                              {errors.designation && (<div className="error-message-view">{errors.designation}</div>)}
                            </div>

                          </div>
                          <div className='emppersonalinfo-input-row'>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department<span className='emppersonalinfo-mandatory'>*</span></label>


                              <input className="emppersonalinfo-input-field3" value={basicInfo.department_name} disabled />

                              {errors.department && (<div className="error-message-view">{errors.department}</div>)}
                            </div>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department Head <span className='emppersonalinfo-mandatory'>*</span></label>

                              <input className="emppersonalinfo-input-field3" value={basicInfo.department_head_name.head_name
                              } disabled />

                              {errors.depart_head && (<div className="error-message-view">{errors.depart_head}</div>)}
                            </div>
                            <div className='emppersonalinfo-input-group'>
                              <label>Department TL
                                <span className='emppersonalinfo-mandatory'>*</span></label>

                              <input className="emppersonalinfo-input-field3" value={basicInfo.department_tl_name.tl_name} disabled />

                              {errors.depart_tl && (<div className="error-message-view">{errors.depart_tl}</div>)}
                            </div>
                          </div>
                          <div className="emppersonalinfo-submitbutton">
                            <button onClick={handleSubmit} disabled={loading}> {loading ? <span className="spinner"></span> : 'Submit'}</button>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Edituserbasicinfo;
