import React, { useState, useEffect, useCallback } from "react";
import "./Header.css";
import './UserSetting.css';
import { CiBellOn, CiSettings } from "react-icons/ci";
//import { isAuthenticated } from "../../Services/Authentication";
//import { Navigate } from "react-router-dom";
import logo from "../../Images/Logo/Logo";
import UserAccount from "./UserAccount";
import UserSettings from "./UserSettings";
import { getCompanyDetails, getStaffDetails, getUserDetails } from "../../Services/Storage";
import api_backend from "../../Config/api";
import userdemoimg from "../../Images/Pagesimg/userdemo";
import Notification from "./Notification/Notification";

const Header = () => {
  // if(!isAuthenticated())
  //   {
  //     return <Navigate to="/" />
  //   }
  const company_details = getCompanyDetails();
  const [shownotification, setnotification] = useState(false);
  const user_details = getUserDetails();
  const staff_details = getStaffDetails();
  const [userpop, viewUserpop] = useState(false);
  const [UserSettingspop, viewUserSettingsPop] = useState(false);
  const toggler_user = () => {
    userpop ? viewUserpop(false) : viewUserpop(true);
    viewUserSettingsPop(false);
  };
  const toggle_setting = () => {
    UserSettingspop ? viewUserSettingsPop(false) : viewUserSettingsPop(true);
    viewUserpop(false);
  }
  const closeDropdowns = useCallback((e) => {
    if ((!e.target.closest(".account-menu") && userpop) || (!e.target.closest(".setting-pop") && UserSettingspop)) {
    } else {
      viewUserpop(false);
      viewUserSettingsPop(false);
    }
  }, [userpop, UserSettingspop]);


  useEffect(() => {
    window.addEventListener("click", closeDropdowns);
    return () => {
      window.removeEventListener("click", closeDropdowns);
    };
  }, [closeDropdowns]);

  const closenoti = () => {
    setnotification(false)
  }
  const viewnotifi = () => {
    setnotification(true)

  }
  return (
    <div className="HedaerNewdiv1">
      <div className="HeaderNewDiv2">
        <div className="HeaderNewDivSub1 hds">
          <div className="sidebar-topview-sub">
            <div className="sidebar-topview-header">
              <span className="sidebar-topview-header-span">
                <img src={logo.logo_colo_change} alt="Company Logo" />
              </span>
            </div>
          </div>
        </div>
        <div className="HeaderNewDivSub2 hds">
          <div className="header-container-sub">
            <span></span>
            <span className="HeaderNewDivSpan">

              {user_details.role === 'Company' && (
                <>
                  <span onClick={viewnotifi}>
                    <CiBellOn size={20} />
                  </span>
                  <span onClick={toggle_setting}>
                    <CiSettings className="header-settings-sub" />
                  </span>
                </>
              )}

              {user_details.role === 'Company'
                ? (
                  <span onClick={toggler_user}>
                    {company_details.company_logo ?
                      (
                        <img
                          className="header-logo-img"
                          src={`${api_backend.BACKEND_API}${company_details.company_logo}`}
                          alt="Login"
                        />
                      ) :
                      ((<img src={userdemoimg.demo_img} className="header-logo-img"
                        alt="img" />)
                      )

                    }

                  </span>
                ) : (
                  <span onClick={toggler_user}>
                    {staff_details && staff_details.staff_details.staff_pic ?
                      (
                        <img
                          className="header-logo-img"
                          src={`${api_backend.BACKEND_API}${staff_details.staff_details.staff_pic}`}
                          alt="Login"
                        />
                      ) :
                      ((<img src={userdemoimg.demo_img} className="header-logo-img"
                        alt="img" />)
                      )

                    }

                  </span>
                )
              }



            </span>
          </div>
        </div>
      </div>
      {user_details.role === 'Company'

      }
      <UserAccount user_status={userpop} onclose={toggler_user} />
      <UserSettings user_status={UserSettingspop} onclose={toggle_setting} />
      <Notification
        show={shownotification}
        close={closenoti}
      />
    </div>
  );
};
export default Header;
