import React, { useState } from 'react';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import Feed_back from '../../../Images/Feedback/Feedback';
import './Feedback.css';
import { AddFeedback } from '../../../Config/api';
import { getCompanyDetails, getUserDetails } from '../../../Services/Storage';
import { toast } from 'react-toastify';

const Feedbackpage = () => {
    const user_details = getUserDetails();
    const company_details = getCompanyDetails();

    const [selectedEmoji, setSelectedEmoji] = useState(null); // State to manage selected emoji
    const [feedbackText, setFeedbackText] = useState(''); // State for feedback text
    const [errors, setErrors] = useState({}); // State for validation errors
    const [loading, setloading] = useState(false)
    // Array of emojis
    const emojis = [
        Feed_back.Emoji1,
        Feed_back.Emoji2,
        Feed_back.Emoji3,
        Feed_back.Emoji4,
        Feed_back.Emoji5,
    ];

    const handleEmojiClick = (index) => {
        setSelectedEmoji(index); // Set the clicked emoji as selected
        setErrors(prev => ({ ...prev, selectedEmoji: null })); // Clear emoji error if present
    };

    const handleFeedbackChange = (e) => {
        setFeedbackText(e.target.value); // Update feedback text as user types
        setErrors(prev => ({ ...prev, feedbackText: null })); // Clear feedback error if present
    };

    const handleSubmit = async () => {
        let validationErrors = {};

        // Validate selected emoji (rating)
        if (selectedEmoji === null) {
            validationErrors.selectedEmoji = 'Please select a rating.';
        }

        // Validate feedback text
        if (feedbackText.trim() === '') {
            validationErrors.feedbackText = 'Please provide feedback.';
        }

        // If there are validation errors, set the errors state and return early
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            toast.error('Please fix the errors before submitting.', {
                position: 'top-center',
                autoClose: 2000,
                closeButton: false,
            });
            return;
        }

        const data = {
            rating_count: selectedEmoji,
            company: company_details.company_id,
            register: user_details.id,
            feedback: feedbackText,
        };

        try {
            setloading(true)
            const response = await AddFeedback(data);
            if (response) {
                toast.success('Feedback Added Successfully!', {
                    position: 'top-center',
                    autoClose: 2000,
                    closeButton: false,
                    className: 'custom_toast',
                });
                setSelectedEmoji(null);
                setFeedbackText('');
            }
        } catch {
            toast.error('Failed. Please try again.', {
                position: 'top-center',
                autoClose: 2000,
                closeButton: false,
            });
        } finally {
            setloading(false)
        }
    };

    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Feedback</p>
                                    <span className='header-search-add-parent'></span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className='pages2-maincontent'>
                                <div className='feedback-main'>
                                    <div className='feedback-card'>
                                        <div className='feedback-card-sub'>
                                            <div className='feedback-card-cont'>
                                                <h3>Give Feedback:</h3>
                                                <div className='feedback-rating-cont'>
                                                    {emojis.map((emoji, index) => (
                                                        <div
                                                            key={index}
                                                            className={`feedback-rating-starcard ${selectedEmoji === index ? 'selected-emoji' : ''
                                                                }`}
                                                            onClick={() => handleEmojiClick(index)}
                                                        >
                                                            <div className='feedback-col-starcard'>
                                                                <div className='feedback-img-view'>
                                                                    <img src={emoji} alt={`Emoji ${index + 1}`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {errors.selectedEmoji && (
                                                    <div className='error-message-view'>{errors.selectedEmoji}</div>
                                                )}
                                                <div className='feedback-input'>
                                                    <p>What's your overall experience.</p>
                                                </div>
                                                <div className='feedback-textarea'>
                                                    <textarea
                                                        value={feedbackText}
                                                        onChange={handleFeedbackChange}
                                                    />
                                                </div>
                                                <br />
                                                {errors.feedbackText && (
                                                    <div className='error-message-view'>{errors.feedbackText}</div>
                                                )}
                                                <div className='feedback-button'>
                                                    <button onClick={handleSubmit} disabled={loading}>{loading ? <span className='spinner'> </span> : "Submit"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feedbackpage;
