import React from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import './Privacy.css'
import Privacy_policy from '../../../Images/PrivacyPolicy/PrivacyPol'
const Privacypage = () => {
    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p> Privacy Policy</p>
                                    <span className='header-search-add-parent'>
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className='pages2-maincontent'>
                                <div className='privay-main'>
                                    <div className='privacy-main-cont'>
                                        <h2>
                                            Privacy Policy for Sea Sense Software Attendance and Payroll Management Software

                                        </h2>
                                        <div className='privay-main-sub'>

                                            <div className='privay-main-sub1'>

                                                <p>
                                                    At Sea Sense Software ("we," "us," "our"), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, share, and safeguard personal information through our attendance and payroll management software (the "Service"). We strive to ensure that all data is processed securely and responsibly, in compliance with applicable privacy laws and regulations.
                                                    <br />
                                                    <br />

                                                    By using our Service, you consent to the collection and use of your information as described in this policy. We collect only the data necessary to deliver and improve the Service, ensuring confidentiality and security. If you do not agree with this policy, you may choose not to use the Service.
                                                </p>

                                            </div>
                                            <div className='privay-main-sub2'>
                                                <div className='privacy-img-first'>
                                                    <img src={Privacy_policy.privacy_pol} alt='img' />

                                                </div>
                                            </div>
                                        </div>
                                        <div className='privacy-sentance'>
                                            <h3>    1. Information We Collect
                                            </h3>

                                            We collect the following types of personal information to provide and improve our Service:
                                            <h4>a) Employee Information
                                            </h4>
                                            <p>
                                                <ul>
                                                    <li>Full Name
                                                    </li>
                                                    <li>Contact Information (e.g., email address, phone number)
                                                    </li>
                                                    <li>Job Title and Department
                                                    </li>
                                                    <li>Employment Start and End Dates
                                                    </li>
                                                    <li>Attendance Records (e.g., clock-in/out times, leave records, etc.)
                                                    </li>
                                                    <li>Work Schedules
                                                    </li>
                                                    <li>Payroll Information (e.g., salary, bonuses, deductions, tax information, payment history)
                                                    </li>
                                                    <li>Identification Numbers (e.g., employee ID, national insurance number, or social security number)
                                                    </li>

                                                </ul>
                                            </p>
                                            <h4>
                                                b) Usage Data

                                            </h4>
                                            <p>
                                                <ul>
                                                    <li>IP address and device information when accessing the software
                                                    </li>
                                                    <li>Browser type, operating system, and access times
                                                    </li>
                                                    <li>Log information about user activity in the software
                                                    </li>


                                                </ul>
                                            </p>
                                            <h3>2. How We Use Your Information
                                            </h3>
                                            We use the collected information for the following purposes:
                                            <p>
                                                <ul>
                                                    <li>
                                                        <b>Attendance and Payroll Management:</b>      Processing employee attendance, managing payroll, calculating taxes, and generating reports.

                                                    </li>
                                                    <li>
                                                        <b>Compliance:
                                                        </b>
                                                        Ensuring compliance with local labor laws, tax regulations, and other legal requirements.
                                                    </li>

                                                    <li>
                                                        <b>
                                                            Communication:

                                                        </b>
                                                        Sending notifications or updates related to attendance, leave requests, payroll, and system maintenance.
                                                    </li>
                                                    <li>
                                                        <b>
                                                            Software Improvement:
                                                        </b>
                                                        Enhancing and personalizing your experience by analyzing usage trends and troubleshooting issues.

                                                    </li>
                                                    <li>
                                                        <b>Security and Fraud Prevention:
                                                        </b>
                                                        Monitoring and ensuring the security of our systems to prevent unauthorized access and data breaches.
                                                    </li>

                                                </ul>
                                            </p>
                                            <h3>3. How We Share Your Information
                                            </h3>
                                            We may share your information with third parties in the following circumstances:
                                            <p>
                                                <ul>
                                                    <li>
                                                        <b>Service Providers:</b>
                                                        We share information with third-party service providers who help us provide and maintain the Service, such as cloud storage providers, payment processors, or IT support.

                                                    </li>
                                                    <li>
                                                        <b>
                                                            Legal Compliance:
                                                        </b>
                                                        We may disclose information if required by law, to comply with a legal obligation, or in response to a valid legal request by public authorities (e.g., a court or government agency).

                                                    </li>
                                                    <li>
                                                        <b>
                                                            Business Transfers:
                                                        </b>
                                                        In the event of a merger, acquisition, or sale of assets, your personal information may be transferred to the new owners as part of the transaction.

                                                    </li>

                                                </ul>
                                            </p>
                                            <h3>
                                                4. Data Retention

                                            </h3>
                                            <p>We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. Once the data is no longer required, we securely delete or anonymize it.
                                            </p>
                                            <h3>5. Your Data Protection Rights
                                            </h3>

                                            Depending on your jurisdiction, you may have the following rights regarding your personal information:

                                            <p>
                                                <ul>
                                                    <li>
                                                        <b>Access:
                                                        </b>
                                                        The right to request a copy of your personal information.
                                                    </li>
                                                    <li>
                                                        <b>
                                                            Correction:
                                                        </b>
                                                        The right to correct inaccurate or incomplete personal information.

                                                    </li>
                                                    <li>
                                                        <b>Deletion:
                                                        </b>
                                                        The right to request deletion of your personal information under certain conditions.
                                                    </li>
                                                    <li>
                                                        <b>
                                                            Restriction:

                                                        </b>
                                                        The right to request restriction of the processing of your personal information.
                                                    </li>
                                                    <li>
                                                        <b>Portability:
                                                        </b>
                                                        The right to request a copy of your personal information in a structured, commonly used format.
                                                    </li>
                                                    <li>
                                                        <b>Objection:
                                                        </b>
                                                        The right to object to the processing of your personal information for specific purposes.
                                                    </li>
                                                </ul>
                                            </p>
                                            To exercise any of these rights, please contact us at [Contact Information].
                                            <h3>6. Data Security
                                            </h3>
                                            <p>
                                                We implement a variety of security measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. These measures include encryption, secure access controls, regular security audits, and staff training on data protection.

                                                However, please note that no system is 100% secure, and we cannot guarantee the absolute security of your data.

                                            </p>
                                            <h3>7. Third-Party Links
                                            </h3>
                                            <p>Our software may contain links to third-party websites or services that are not operated by us. We are not responsible for the privacy practices or content of such third parties. We encourage you to review their privacy policies before providing any personal information.
                                            </p>
                                            <h3>8. International Data Transfers
                                            </h3>
                                            <p>If you are located outside the India, your personal information may be transferred to and processed in countries other than your own. By using our Service, you consent to the transfer of your information to countries that may have different data protection laws than your jurisdiction.
                                            </p>
                                            <h3>9. Children’s Privacy
                                            </h3>
                                            <p>
                                                Our Service is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we learn that we have inadvertently collected such information, we will take steps to delete it.

                                            </p>
                                            <h3>10. Changes to This Privacy Policy
                                            </h3>
                                            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website and updating the "Effective Date" at the top of the policy.
                                            </p>
                                            <h3>11. Contact Us
                                            </h3>
                                            <p>If you have any questions about this Privacy Policy or our data protection practices, please contact us at:
                                            </p>
                                            <p>Sea Sense Software</p>
                                            <p>Marthandam</p>

                                            <p>seasense@gmail.com</p>

                                            <p>4561237890</p>

                                            <p>
                                                This policy ensures transparency about data collection and processing practices, while emphasizing security and compliance. Tailor the policy to fit the specifics of your software and any applicable laws.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacypage;