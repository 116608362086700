const DashboardImages = {
    activeusers : "./Images/Dashboard_images/active_users.png",
    deactiveusers : "./Images/Dashboard_images/deactive_users.png",
    leave : "./Images/Dashboard_images/leave.png",
    loan : "./Images/Dashboard_images/loan.png",
    usersall:"./Images/Dashboard_images/usersall.png",
    dashboard_main:"./Images/Dashboard_images/first_img.png",
    payroll : "./Images/Dashboard_images/payroll.png",
    totalleave : "./Images/Dashboard_images/totalleave.png",
    totalworking : "./Images/Dashboard_images/totalworking.png",
    pendingleave : "./Images/Dashboard_images/pendingleave.png",
   

}
export default DashboardImages;
