import React, { useState, useEffect, useCallback } from "react";
import Header from "../../Header/Header";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "../../Dashboard/Index.css";
import Sidebar from "../../Sidebar/Sidebar";
import "../../../Assets/Pages.css";
import { CiSearch } from "react-icons/ci";
import formatDate from "../../Component/DateConvert";
import { Fetchallemployeeprint, UpdateEmployeeStatus, ViewEditEmployeeApi, ViewEmployeeApi, viewSalaryComponentApi, ViewSelectedPrint } from "../../../Config/api";
import { getUserDetails } from "../../../Services/Storage";
import ReactPaginate from "react-paginate";
import "../../../Assets/Pagination.css";
import {
  CompanyApi,
  DepartmentApi,
  DepartmentHeadApi,
  DepartmentTLApi,
  DesignationApi,
} from "../../../Config/CommonApi";
import { FcPrint } from "react-icons/fc";

import nodatafound from "../../../Images/Noimagefound/Noimgfound";
import pageloader from "../../../Loader/pageloading/Pageloader";
// import Refreshimg from "../../../Images/Refreshimg/Refreshimg";
// import Action from "../../../Images/Action/action";
import Confirmation from "../../Component/Confirmation";
import { setEmployees as setEmployeesRedux } from "../../../Slice/employeeSlice";
import { Store } from "../../../Store";
import Employeesatus from "./Employeestatus";
import Deleteconfirmation from "../../Component/Deleteconfirmation";
import EmployeeDetails from "./Printallemp";
import { setSalaryComponent as setSalaryComponentRedux } from '../../../Slice/salaryComponentSlice';

const ViewEmployee = () => {
  const [currentpage, setCurrentPage] = useState(1);
  const [search, setSearchword] = useState("");
  const [companyFilter, setCompanyFilter] = useState([]);
  const [company, setCompany] = useState('');
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("all");
  const [loading, setloading] = useState(null);
  const [statusToUpdate, setStatustoupdate] = useState(null)
  const [isloading, setIsLoading] = useState(true);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [openalert, setopenalert] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [departmentfilter, setdepartmentfilter] = useState([]);
  const [designationfilter, setdesignationfilter] = useState([]);
  const [userfilter, setuserfilter] = useState({ value: "all", label: "All Users" });
  const [printalldata, setprintalldatas] = useState([]);
  const [printpopup, setprintpop] = useState(false);


  const user_details = getUserDetails();
  const [dataalert, setalartdata] = useState()
  const navigate = useNavigate();
  const per_page = 10;
  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await CompanyApi();
      setCompanyData(data);
      setCompanyFilter(data[0]);
    };
    const fetchDepartmentData = async () => {
      const data = await DepartmentApi();
      setDepartmentData(data);
      setdepartmentfilter(data[0]);
    };
    const fetchDesignationData = async () => {
      const data = await DesignationApi();
      setDesignationData(data);
      setdesignationfilter(data[0])
    };
    const fetchDepartmentHeadData = async () => {
      await DepartmentHeadApi();
    };
    const fetchDepartmentTlData = async () => {
      await DepartmentTLApi();
    };
    const fetchsalaryComponent = async () => {
      try {
        const response = await viewSalaryComponentApi(`?register_id=${user_details.id}`);
        Store.dispatch(setSalaryComponentRedux(response.data.get_salary_component_earning));
      } catch (error) {
      }
    }
    fetchCompanyData();
    fetchDepartmentData();
    fetchDesignationData();
    fetchDepartmentHeadData();
    fetchDepartmentTlData();
    fetchsalaryComponent();
    // printallemployee();

  }, [user_details.id]);
  const handleChangeCompany = (selecteOption) => {
    setCompany(selecteOption.value);
    setCompanyFilter(selecteOption)
  };
  const handleChangeDepartment = (selecteOption) => {
    setDepartment(selecteOption.value);
    setdepartmentfilter(selecteOption);
  };
  const handleChangeDesignation = (selecteOption) => {
    setDesignation(selecteOption.value);
    setdesignationfilter(selecteOption)
  };
  const handleChangeList = (selecteOption) => {
    setEmployeeStatus(selecteOption.value);
    setuserfilter(selecteOption)
  };
  const listStatus = [
    { value: "all", label: "All Users" },
    { value: "active", label: "Active Users" },
    { value: "deactive", label: "Deactive Users" },
  ];

  const fetchEmployeeList = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await ViewEmployeeApi(
        `?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}&company=${company}&department=${department}&user_status=${employeeStatus}&designation=${designation}`
      );
      setEmployees(response.data.get_staff_details);
      setTotalEmployeeCount(response.data.page_context.total);
    } catch (error) {
    } finally {
      setIsLoading(false);

    }
  }, [currentpage, company, department, designation, employeeStatus, search, user_details.id]);

  // const fetchEmployeeListDefault = async () => {
  //   setCurrentPage(1);
  //   setCompany('');
  //   setDepartment('');
  //   setDesignation('');
  //   setEmployeeStatus('');
  //   setReloadKey(prevKey => prevKey + 1);
  // };

  useEffect(() => {
    fetchEmployeeList();
  }, [fetchEmployeeList]);

  useEffect(() => {
    if (selectedEmployees.length === employees.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedEmployees, employees.length]);

  const handeactionbutton = (status) => {
    setStatustoupdate(status);
    setShowConfirmModal(true);

  }
  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees(employees.map((employee) => employee.register_id));
    }
    setIsAllChecked(!isAllChecked);
  };
  const handleCheckboxChange = (id) => {
    setSelectedEmployees((prevState) =>
      prevState.includes(id)
        ? prevState.filter((empId) => empId !== id)
        : [...prevState, id]
    );
  };
  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };
  const handleConfirm = async () => {
    setShowConfirmModal(false);
    const payload = {
      status: statusToUpdate,
      ids: selectedEmployees
    }
    try {
      setloading(statusToUpdate);

      const response = await UpdateEmployeeStatus(JSON.stringify(payload));
      if (response) {
        fetchEmployeeList();

      }
    } catch {

    } finally {
      setloading(null)
    }
  }

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  }
  const editPage = async (user_id) => {
    try {
      const response = await ViewEditEmployeeApi(user_id);
      if (response.data.status === 200) {
        if (response.data.get_staff_details.length > 0) {
          Store.dispatch(setEmployeesRedux(response.data.get_staff_details[0]));
          navigate('/vieweditdetails');
        } else {

        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        setopenalert(true)
        setalartdata(error.response.data);
      }

    }
  }
  const handleaddbutton = () => {
    navigate("/addemployee");
  };
  const handleincompbutton = () => {
    navigate('/incompemployee')
  }
  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  const EmployeeStatusText = (status) => {
    switch (status) {
      case '0':
        return 'Deactive'
      case '1':
        return 'Active';
      default:
        return 'None';

    }
  }

  const closealert = () => {
    setopenalert(false)
    setprintalldatas("")
  }

  // const handleprintalldata =()=>{
  // }

  const handleprintalldatacle = () => {
    setprintpop(false);

  }
  const handleprintalldata = async () => {
    setprintpop(true);

    try {
      const response = await Fetchallemployeeprint(`?register_id=${user_details.id}`)
      setprintalldatas(response.data.print_all_emp_details);
    } catch {

    }
  }
  const handleselectedemp = async () => {
    const data = {
      register_id: selectedEmployees, // Assuming selectedEmployees is defined and contains the IDs
    };
    setprintpop(true);

    try {
      // Call the ViewSelectedPrint function with the data
      const response = await ViewSelectedPrint(JSON.stringify(data));

      if (response) {
        setprintalldatas(response.print_single_emp_details);
        // Handle the successful response here, e.g., printing employee details
      } else {
      }
    } catch (error) {
    }
  };

  return (
    <>
      <div className="index-container">
        <Header />
        <div className="index-content-parent">
          <div className="index-content">
            <Sidebar />
            <div className="index-content-sub">
              <div className="index-main">
                <div className="pages-subheader">
                  {selectedEmployees.length === 0 ? (
                    <>
                      <div className="pages-subheader-sub-1">
                        <p>Active Employees</p>
                        <span className="header-search-add-parent">

                          <div className="pages-serach-view">
                            <CiSearch className="page-search-icon" />
                            <input
                              onChange={handleSearch}
                              className="pages-serach"
                              placeholder="Search Employee"
                              type="search"
                            />
                          </div>
                          <button className="pages-addempbutton" onClick={handleprintalldata}>

                            Print
                          </button>
                          <button
                            className="pages-addempbutton"
                            onClick={handleincompbutton}
                          >
                            Incomplete
                          </button>
                          <button
                            className="pages-addempbutton"
                            onClick={handleaddbutton}
                          >
                            Add Employee
                          </button>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pages-subheader-sub-2">

                        <button className="pages-action-button normal" onClick={handleselectedemp}>

                          <FcPrint size={15} /> Print
                        </button>

                        <button className='pages-action-button approve' onClick={() => handeactionbutton(0)} disabled={loading !== null}> {loading === 0 ? <span className='spinner-status'></span> : 'Active'}</button>

                        <button className='pages-action-button reject' onClick={() => handeactionbutton(1)} disabled={loading !== null} > {loading === 1 ? <span className='spinner-status'></span> : 'Deactive'} </button>

                      </div>
                    </>
                  )}
                  <div className="pages-line"></div>
                </div>
                <div className="pages-filter-options">
                  <div className="pages-filter-sub">
                    <p>Filter:</p>
                    <Select
                      className="pages-dropdown1"
                      options={companyData}
                      onChange={handleChangeCompany}
                      value={companyFilter}
                      placeholder="Select Work Location"
                    />
                    <Select
                      className="pages-dropdown"
                      options={departmentData}
                      value={departmentfilter}
                      onChange={handleChangeDepartment}
                      placeholder="Select Department"
                    />
                    <Select
                      className="pages-dropdown"
                      options={designationData}
                      value={designationfilter}
                      onChange={handleChangeDesignation}
                      placeholder="Select Designation"
                    />
                    <Select
                      className="pages-dropdown"
                      onChange={handleChangeList}
                      options={listStatus}
                      value={userfilter}
                      placeholder="Active List"
                    />
                    {/* <img src={Refreshimg.refreshimg} onClick={fetchEmployeeListDefault} /> */}
                  </div>
                </div>
                <div className="pages-maincontent">
                  {isloading ? (
                    <div className="loader-container">
                      <img src={pageloader.page_loader} alt="Loading..." />
                    </div>
                  ) : (
                    <table className="pages-table">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={isAllChecked}
                              onChange={handleAllCheckboxChange}
                            />
                          </th>
                          <th>Name</th>
                          <th>Employee Id</th>
                          <th>Work Email</th>
                          <th>Phone</th>
                          <th>Department</th>
                          <th>Salary</th>
                          <th>Date of Joining</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employees.length > 0 ? (
                          employees.map((employee, index) => (
                            <tr key={employee.register_id}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedEmployees.includes(employee.register_id)}
                                  onChange={() => handleCheckboxChange(employee.register_id)}
                                />
                              </td>
                              <td>
                                <span onClick={() => editPage(employee.register_details.id)} className="link-span">{employee.register_details.name}</span>
                              </td>
                              <td>{employee.work_details.emp_id}</td>
                              <td>{employee.work_details.work_email}</td>
                              <td>{employee.register_details.phone}</td>
                              <td>{employee.work_details.department_name}</td>
                              <td>
                                {employee.salary_details
                                  ? employee.salary_details.monthly_salary
                                  : "N/A"}
                              </td>
                              <td>
                                {employee.work_details.doj
                                  ? formatDate(employee.work_details.doj)
                                  : "N/A"}
                              </td >
                              <td style={{ color: Employeesatus(employee.register_details.user_status).color }}>{Employeesatus(employee.register_details.user_status).text}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="no-data-table">
                              <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )
                  }
                  <div style={{ float: "right" }} id="pagination_link"></div>
                  <div style={{ padding: "20px" }}>
                    {totalEmployeeCount > per_page ? (
                      <>
                        <ReactPaginate
                          pageCount={Math.ceil(totalEmployeeCount / per_page)}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          onPageChange={currentPageNo}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </>
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Confirmation
          isOpen={showConfirmModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
          message={`Are you sure you want to ${EmployeeStatusText(String(statusToUpdate))} the selected Employee?`}
        />
        <Deleteconfirmation
          show={openalert}
          close={closealert}
          data={dataalert} // Pass data to the confirmation popup

        />
        <EmployeeDetails
          employeeData={printalldata}
          show={printpopup}
          close={handleprintalldatacle}
        />
      </div>
    </>
  );
};

export default ViewEmployee;
