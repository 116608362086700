const apiUrl = {
    REGISTER_URL            : "api/registration/",
    COMPANY_URL             : "api/company_details/",
    LOGIN_URL               : "api/login/",
    VIEW_EMPLOYEE           : "api/get_staff_details/",
    VIEW_EDIT_EMPLOYEE      : "api/individual_staff_details/",
    VIEW_COMPANY            : "api/get_company_details",
    VIEW_DEPARTMENT         : "api/get_department_details",
    VIEW_DESIGNATION        : "api/get_designation_details",
    VIEW_DEPARTMENT_HEAD    : "api/get_depart_head",
    VIEW_DEPARTMENT_TL      : "api/get_depart_tl",
    UPDATE_STAFF            : "api/update_staff",
    UPDATE_REGISTER         : "api/update_register",
    UPDATE_PAYMENT          :  "api/update_payment",
    STAFF_BASIC_URL         :  "api/staff_register/",
    STAFF_PERSONALINF_URL   :"api/registration_staff/",
    STAFF_SALARY_URL            : "api/add_salary/",
    STAFF_PAYMENT_URL           : "api/payment_details/",   
    VIEW_LEAVE              : "api/empleave_view_admin/",
    FETCH_EMPLOYEE          : "api/get_empid_empname/",
    ADD_LEAVE               : "api/add_emp_leave/",
    FETCH_LEAVE_URL         : "api/get_leave_component/",
    VIEW_SALARY_COMPONENT_URL   : "api/get_salary_component/",
    UPDATE_LEAVE_STATUS     : "api/update_leave_status_checkbox/",
    EXCEL_TO_DATABASE_URL           : "api/excel_to_database/",
    VIEW_ATTENDANCE_URL             : "api/view_attendance/",
    VIEW_EMP_ATTENDANCE_COUNT       :"api/view_emp_attendance_count/",
    VIEW_SINGLE_ATTENDANCE_URL      : "api/view_single_attendance/",
    SALARY_STAFF_VIEW_URL           : "api/salary_staff_view/",
    SINGLE_STAFF_SALARY_SLIP_URL    : "api/single_staff_all_salary_report/",
    STAFF_SALARY_SLIP_URL           : "api/staff_salary_slip/",
    SALARY_CALCULATION_URL          : "api/salary_calculation/",
    SALARY_STATUS_APPROVED          : "api/salary_status_approved/",
    UPDATE_SALARY_DETAIL_URL        : "api/update_salary/",
    UPDATE_EMPLOYEE_STATUS          : "api/active_deactive_multiuser/",
    ADD_SALARY_COMPONENT            : "api/add_salary_component/",
    VIEW_SALARY_COMPONENT_DEDUCTION_URL   : "api/get_salary_component_deduction/",
    DELETE_SALARY_COMPONENT_URL     :   "api/delete_salary_component/",
    UPDATE_SALARY_COMPONENT_URL     :   "api/update_salary_component/",
    FETCH_EARNINGS_SALARY           :"api/get_salary_component_earning/",
    FETCH_LEAVE_COMP_URL            :"api/get_leave_component/",
    ADD_LEAVE_COMPONENT_URL         :"api/add_leave_component/",
    UPDATE_LEAVE_COMPONENT_URL      :"api/update_leave_component/",
    DELETE_LEAVE_COMP               :"api/delete_leave_component",
    VIEW_DEPARTMENTCOMP_URL         :"api/view_department",
    UPDATE_DEPARTMENT_URL           :"api/update_department",
    ADD_DEPARTMENT_URL              :"api/department/",
    DELETE_DEPARTMENT_URL           :"api/delete_department",
    VIEW_DESIGNATION_URL            :"api/view_designation",
    ADD_DESIGNATION_URL             :"api/designation/",
    UPDATE_DESIGNATION_URL          :"api/update_degnationt",
    DELETE_DESIGNATION_URL          :"api/delete_desigantion/",
    VIEW_DEPARTMENT_HEAD_URL        :"api/view_department_head/",
    UPDATE_DEPARTMENT_HEAD_URL      :"api/update_head/",
    DELETE_DEPARTMENT_HEAD_URL      :"api/delete_head/",
    ADD_DEPARTMENT_HEAD_URL         :"api/department_head/",
    VIEW_DEPARTMENT_TL_URL          :"api/view_department_tl/",
    ADD_DEPARTMENTTL_URL            :"api/department_tl/",
    UPDATE_DEPARTMENTTL_URL         :"api/update_tl/",
    DELETE_DEPARTMENTTL_URL         :"api/delete_tl/",
    VIEW_ORGANIZATION_PROFILE       :"api/get_company_details/",
    UPDATE_COMPANY_URL              :"api/update_company",
    ADD_COMPANYDETAILS_URL          :"api/company_details/",
    UPDATE_USERDETAILS_UTL          :"api/update_register",
    CHANGE_PASSWORD_URL             :"api/change_password/",
    ADD_FEEDBACK                    :"api/add_feedback/",
    VIEW_HOLIDAY                    :"api/view_holiday",
    ADD_HOLIDAY                     :"api/add_holiday/",
    ADD_NOTES_URL                   :"api/add_notes/",
    DELETE_HOLIDAY                  :"api/delete_holiday/",
    STAFF_ALL_DETAILS               :"api/staff_all_details/",
    STAFF_LEAVE_VIEW                :"api/empleave_view/",
    VIEW_NOTIFICATION               :"api/get_notification_admin/",
    VIEW_DASHBOARD_COUNT            :"api/dashboard_view/",
    ADD_STAFF_LEAVE                 :"api/add_leave_from_staff/",
    DELETE_STAFF_LEAVE              :"api/delete_emp_leave/",
    FORGOT_PASSWORD                 :"api/forget_password/",
    FORGOT_PASSWORD_EMAIL_VALIDATE  :"api/forget_passwordemail/",
    VIEW_PENDING_LEAVE_DASHBOARD    :"api/pending_leave_view/",
    VIEW_NEWEMPLOYEE_LIST           :"api/employee_filter_by_date/",
    FETCH_DASHBOARD_STAFFCOUNT      :"api/staff_login_dashboard/",
    FETCH_DASHBOARD_STAFFPAYROLL    :"api/salary_report_last_three_month/",
    FETCH_PENDING_STAFFLEAVE        :"api/pending_leaveview_staff/",
    INCOMPLETE_REGISTERATION        :"api/incomplete_registration/",
    FETCH_DEP_TL_WITH_ID            :"api/get_tl_based_department/",
    FETCH_DEP_HEAD_WITH_ID          :"api/get_head_based_department/",
    PRINT_ALL_EMPLOYEEE             :"api/print_all_employee/",
    PRINT_SELECTED_EMPLOYEE         :"api/print_single_employee/",
    ADD_ATTENDANCE_EMPLOYEE         :"api/add_attendance/",
    EDIT_ATTENDANCE_EMPLOYEE        :"api/edit_attendance/",
    DELETE_ATTENDANCE               :"api/delete_attendance/",
    RESENT_OTP                      :"api/resend_otp_email/",
    OTPVERIFY_REGISTER              :"api/register_otp/"

}

export default apiUrl;