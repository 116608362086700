import {  getUserToken } from './Storage';

export const isAuthenticated = () => {
    const token = getUserToken();
    return token != null;
};

// export const isCompany =()=>{
//     const company = getCompanyDetails();
//     return company != null;
// }