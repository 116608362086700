import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
import { getCompanyDetails, getUserDetails } from './Services/Storage';

const PrivateRoute = ({ element, roles }) => {
    const userDetails = getUserDetails();
    const companyDetails = getCompanyDetails();
    const authUser = userDetails ? userDetails : null;
    const companyUser = companyDetails ? companyDetails : null;
    const [usersrole] = useState(authUser ? authUser.role : null);
    if (authUser !== null) {
        if (usersrole === 'Company') {
            if (companyUser) {
                if (roles && roles.length > 0 && !roles.includes(usersrole)) {
                    return <Navigate to="/unauthorized" />;
                }
                return element;
            }
            else {
                return <Navigate to="/compdetails" />;
            }
        }
        else if (usersrole === 'staff') {
            if (roles && roles.length > 0 && !roles.includes(usersrole)) {
                return <Navigate to="/unauthorized" />;
            }
            return element;
        }
        else {
            if (roles && roles.length > 0 && !roles.includes(usersrole)) {
                return <Navigate to="/unauthorized" />;
            }
            else {
                return companyUser ? element : <Navigate to="/login" />;
            }
        }
    }
    else {
        return <Navigate to="/login" />;
    }
}

export default PrivateRoute