import axios from "axios";
import { getUserDetails, getUserToken } from "../Services/Storage";
import apiUrl from "./Urls";
import { Store } from "../Store";
import { setCompanies } from "../Slice/companySlice";
import { setDesignations } from "../Slice/designationSlice";
import { setDepartment } from "../Slice/departmentSlice";
import { setDepartmentTL } from "../Slice/DepartmentTlSlice";
import { setDepartmentHead } from "../Slice/departmentHeadSlice";

export const CompanyApi = async () => {
    const user_details = getUserDetails();
    var datatoken = getUserToken();
    const userId = `/?register_id=${user_details.id}`;
    try {
        const response = await axios.get(`${apiUrl.VIEW_COMPANY}${userId}`, {
            headers: {
                Authorization: `Bearer ${datatoken}`,
                "Content-Type": "multipart/form-data",
            }
        });
        if (response.data.status === 200) {
            const companyData = response.data.get_company.map(company => ({
                value: company.id,
                label: company.company_name,
            }));
            Store.dispatch(setCompanies(response.data.get_company));
            return [{ value: 'all', label: 'All Branch' }, ...companyData];
        } else {
            return [{ value: 0, label: "No Data Found" }];
        }
    } catch (error) {
        return [{ value: 0, label: "No Data Found" }];
    }
};

export const DepartmentApi = async () => {
    const user_details = getUserDetails();
    var datatoken = getUserToken();
    const userId = `/?register_id=${user_details.id}`;
    try {
        const response = await axios.get(`${apiUrl.VIEW_DEPARTMENT}${userId}`, {
            headers: {
                Authorization: `Bearer ${datatoken}`,
                "Content-Type": "multipart/form-data",
            }
        });
        if (response.data.status === 200) {
            const DepartmentData = response.data.get_department.map(department => ({
                value: department.id,
                label: department.depart_name,
            }));
            Store.dispatch(setDepartment(response.data.get_department));
            return [{ value: "all", label: "All Department" }, ...DepartmentData];
        }
        else {
            return [{ value: 0, label: "No Data Found" }];
        }
    }
    catch (error) {
        return [{ value: 0, label: "No Data Found" }];
    }
}


export const DesignationApi = async () => {
    const user_details = getUserDetails();
    var datatoken = getUserToken();
    const userId = `/?register_id=${user_details.id}`;
    try {
        const response = await axios.get(`${apiUrl.VIEW_DESIGNATION}${userId}`, {
            headers: {
                Authorization: `Bearer ${datatoken}`,
                "Content-Type": "multipart/form-data",
            }
        });
        if (response.data.status === 200) {
            const DesignationData = response.data.get_designation.map(designation => ({
                value: designation.id,
                label: designation.designation,
            }));
            Store.dispatch(setDesignations(response.data.get_designation));
            return [{ value: "all", label: "All Designation" }, ...DesignationData];
        }
        else {
            return [{ value: 0, label: "No Data Found" }];
        }
    }
    catch (error) {
        return [{ value: 0, label: "No Data Found" }];
    }
}

export const DepartmentHeadApi = async () => {
    const user_details = getUserDetails();
    var datatoken = getUserToken();
    const userId = `/?register_id=${user_details.id}`;
    try {
        const response = await axios.get(`${apiUrl.VIEW_DEPARTMENT_HEAD}${userId}`, {
            headers: {
                Authorization: `Bearer ${datatoken}`,
                "Content-Type": "multipart/form-data",
            }
        });
        if (response.data.status === 200) {
            Store.dispatch(setDepartmentHead(response.data.get_depart_head));
        }
        else {
            return [{ value: 0, label: "No Data Found" }];
        }
    }
    catch (error) {
        return [{ value: 0, label: "No Data Found" }];
    }
}

export const DepartmentTLApi = async () => {
    const user_details = getUserDetails();
    var datatoken = getUserToken();
    const userId = `/?register_id=${user_details.id}`;
    try {
        const response = await axios.get(`${apiUrl.VIEW_DEPARTMENT_TL}${userId}`, {
            headers: {
                Authorization: `Bearer ${datatoken}`,
                "Content-Type": "multipart/form-data",
            }
        });
        if (response.data.status === 200) {
            Store.dispatch(setDepartmentTL(response.data.get_depart_tl));
        }
        else {
            return [{ value: 0, label: "No Data Found" }];
        }
    }
    catch (error) {
        return [{ value: 0, label: "No Data Found" }];
    }
}
