const formatDate = (dateString) => {
  if (!dateString || typeof dateString !== 'string') {
    return 'Invalid Date'; // Handle invalid input
  }

  const parts = dateString.split('-');
  if (parts.length !== 3) {
    return 'Invalid Date'; // Handle incorrect format
  }

  const [year, month, day] = parts;
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const monthIndex = parseInt(month, 10) - 1; // Convert to zero-based index
  if (monthIndex < 0 || monthIndex > 11) {
    return 'Invalid Date'; // Handle invalid month
  } 
  return `${day}-${monthNames[monthIndex]}-${year}`;
};

export default formatDate;

// const formatDate = (dateString) => {
//   const [year, month, day] = dateString.split('-');
//   return `${day}-${month}-${year}`;
// };

// export default formatDate;