import React, { useEffect, useState } from 'react';
import './Login.css'
import carousel1 from '../../Images/Carousel/Carousel1';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { SignInApi } from '../../Config/api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { storeCompanyDetails, storeUserDetails, storeUserToken } from '../../Services/Storage';
import {  Navigate, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../Services/Authentication';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); 
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  }); const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleKeyPress =(e)=>{
    if (e.target.name === 'password') {
      const specialCharPattern = /[!@#$%^&*(),.?":{}|<>+_]/;
      if (specialCharPattern.test(e.key)) {
        e.preventDefault(); // Prevent input
      }
    }
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const handleSubmit = async () => {
    let newErrors = {};
    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    } if (formData.password.trim() === '') {
      newErrors.password = 'Password is required';
    }
    if (formData.password.trim() === '') {
      newErrors.password = 'Password is required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await SignInApi(formData);
        if (response) {
          storeUserToken(response.user_details.fcm_token);
          storeUserDetails(response.user_details);
          storeCompanyDetails(response.company_details);
          toast.success('Login successful!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          });
          setTimeout(() => {
            navigate('/dashboard');
          }, 100); // Navigate after 2 seconds (2000 ms)
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key]; // Assign the specific error message to the respective field
            }
          }
    
          setErrors(newErrors);
        } else {
          toast.error('Failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
    
          });
    
          // Handle generic errors
          setErrors({
            response_error: 'Please try again later.',
          });
        }
      }
      finally {
        setLoading(false);
      }
    }
  };
  const handleKeyPressEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

 
  const [cpserror, setCapsError] = useState("");
  useEffect(() => {
    const handleCapsLock = (e) => {
      const isCapsLockOn = e.getModifierState
        ? e.getModifierState("CapsLock")
        : false;
      if (isCapsLockOn) {
        setCapsError("Caps Lock is on");
      } else {
        setCapsError("");
      }
    }; document.addEventListener("keydown", handleCapsLock); return () => {
      document.removeEventListener("keydown", handleCapsLock);
    };
  }, []);
  // if (isAuthenticated()) {
  //   return <Navigate to="/dashboard" />
  // } 
  
if(isAuthenticated())
  {
    return <Navigate to="/dashboard" />
  }
  return (
    <div className='login-container'>
      <div className='login-container-sub'>
        <div className='login-form'>
        <div className='login-content-sub'>
        <div className='login-main-cont'>
         
          <div className='login-content'>
          <div className='login-logo'>
            <p>Welcome Back!</p>
            <h2>Login to your account</h2>
          </div>
          <div className='login-input-div'>
          <label> Email</label>
            <input type='text' onKeyDown={handleKeyPressEnter} name='email' onChange={handleInputChange} />
            {errors.email && (
              <div className="error-message-view">{errors.email}</div>
            )}
          </div>
          <div className='login-input-div'>
          <label>Password</label>
          <input 
                    type={showPassword ? "text" : "password"} 
                    onKeyDown={handleKeyPressEnter} 
                    name='password' 
                    onChange={handleInputChange} 
                    onKeyPress={handleKeyPress} // Add the onKeyPress event
                  />         
                    <FontAwesomeIcon className="login-password-icon" icon={showPassword ? faEye : faEyeSlash} onClick={togglePasswordVisibility} />{errors.password && (
              <div className="error-message-view">{errors.password}</div>
            )}
          </div>
           
            <div className='login-forgot-password'>
            <Link to="/forgot-password">
            Forgot Password?
</Link>
       </div>
            {errors.response_error && (
              <div className="error-message-view">{errors.response_error}</div>
            )}
            <div className="error">{cpserror}</div>
            <div className='login-btn'>
            <button onClick={handleSubmit} disabled={loading}> {loading ? <span className='spinner'></span> : 'Submit'}
            </button>
            </div>
           
            <div className='login-signup'>
              <p >Don't have a Staffin account?
              <Link to="/signup">

               Sign up
                </Link>

              </p>
            </div>
          </div>
          </div>
        </div>
        </div>
        <div className='login-carousel'>
          {/* <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} showArrows={false}
            interval={3000}>
            <div>
              <img src={carousel1.carousel_1} alt='carousel-img-1' /></div>
            <div>
              <img src={carousel2.carousel_2} alt='carousel-img-2' />
            </div></Carousel> */}
              <div className='login-page-image'>
              <img src={carousel1.carousel_1} alt='carousel-img-1' /></div>
        </div>
      </div>
      </div>
  )
}
export default Login