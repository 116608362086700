import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        addDepartment(state, action) {
            state.push(action.payload)

        },
        updatedepartcomp(state, action) {
            const updatedComponent = action.payload;
            return state.map((component) =>
                component.id === updatedComponent.id
                    ? { ...component, ...updatedComponent }
                    : component
            )
        },
        setDepartment(state, action) {
            return action.payload;
        },
        deleteDepartmentComp(state, action) {
            const componentId = action.payload;
            return state.filter((component) => component.id !== componentId);
        },
    }
})

export const { addDepartment, setDepartment, updatedepartcomp, deleteDepartmentComp } = departmentSlice.actions;
export default departmentSlice.reducer;