import React, { useState } from 'react'
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Viewslippopup from '../viewslippopup/Viewslippopup';
import { useLocation } from 'react-router-dom';
import { viewStaffSalarySlipApi } from '../../../Config/api';
import monthNames from '../../Component/Monthview';
import { getUserDetails } from '../../../Services/Storage';
const Viewsalaryslip = () => {
    const location = useLocation();
    const { data, userName } = location.state || {};
    const [ispopviewslip, showpopupslip] = useState(false);
    const [slipData, setslipData] = useState([]);
    const user_details = getUserDetails();

    const openpopslip = async (id, month, year) => {
        try {
            const response = await viewStaffSalarySlipApi(
                `?register=${id}&month=${month}&year=${year}&admin_id=${user_details.id}`
            );
            if (response.data) {
                setslipData(response.data.salary_report[0])
                showpopupslip(true);
            }
        }
        catch (error) {

        }
    }
    const closepopupslip = () => {
        showpopupslip(false);
    }

    const employees = data ? data.salary_report : [];

    if (!data) {
        return <div> No salary slip data available. </div>;
    }
    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>


                                <div className='pages-subheader-sub-1'>
                                    <p>Salary Report</p>
                                    {/* <span className='header-search-add-parent'>
                      <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon'/>
                      <input className='pages-serach' placeholder='Search Payroll' type='search'/>
                      </div> 
                      </span> */}
                                </div>

                                <div className='pages-line'></div>
                            </div>
                            <div className='pages-filter-options'>
                                <div className='pages-filter-sub'>
                                    <p className='page-pra-username'>{userName ? userName : ''}</p>
                                </div>
                            </div>
                            <div className='pages-maincontent'>
                                <table className='pages-table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Sl No</th>
                                            <th>Month/Year</th>
                                            <th>Actual Salary</th>
                                            <th>Deduction</th>
                                            <th>Earning</th>
                                            <th>Slip</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employees.map((employee, index) => (
                                            <tr key={employee.id}>
                                                <td></td>
                                                <td> {index + 1} </td>
                                                <th>{monthNames[employee.month]} / {employee.year}</th>
                                                <td>{employee.monthly_salary}</td>
                                                <td>{employee.deduct.toFixed(2)}</td>
                                                <td>{employee.earning.toFixed(2)}</td>
                                                <td onClick={() => openpopslip(employee.register_id, employee.month, employee.year)}>
                                                    <div className='viewattn-view-button'>Slip</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Viewslippopup
                show={ispopviewslip}
                close={closepopupslip}
                salarydata={slipData}
            />
        </div>
    )
}

export default Viewsalaryslip