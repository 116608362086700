// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import companyReducer from './Slice/companySlice';
import designationReducer from './Slice/designationSlice';
import departmentReducer from './Slice/departmentSlice';
import employeeReducer from './Slice/employeeSlice';
import departmentHeadReducer from './Slice/departmentHeadSlice';
import departmentTlReducer from './Slice/DepartmentTlSlice';
import leaveReducer from './Slice/leaveSlice';
import leaveComponentRedux from './Slice/leaveComponentSlice';
import salaryComponentRedux from './Slice/salaryComponentSlice';

// Define the persist config
const persistConfig = {
  key: 'root',
  storage,
};

// Combine your reducers
const rootReducer = combineReducers({
  companies: companyReducer,
  designations: designationReducer,
  department: departmentReducer,
  employees: employeeReducer,
  departmentHead: departmentHeadReducer,
  departmentTl: departmentTlReducer,
  leavecomponents: leaveComponentRedux,
  leaves: leaveReducer,
  salarycomponent: salaryComponentRedux,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const Store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

// Create a persistor
const persistor = persistStore(Store);

export { Store, persistor };
