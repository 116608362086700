
const Employeesatus = (status) => {
    switch (status) {
        case '0':
            return { text: 'Active', color: 'green' };
        case '1':
            return { text: 'Deactive', color: 'red' };
        default:
            return { text: 'None', color: 'gray' };



    }
}
export default Employeesatus;