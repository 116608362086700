import React from 'react'
import './UserSetting.css';
import { Link } from 'react-router-dom';
import { CiCircleList } from 'react-icons/ci';
import { FiAnchor, FiBookOpen, FiKey, FiLock, FiUser, FiUserCheck } from 'react-icons/fi';
import { AiFillBuild } from 'react-icons/ai';
const UserSettings = ({ user_status, onclose }) => {
  if (!user_status) return null;
  return (
    <div className="setting-pop">
      <div className="setting-pop-div1">
        <span className="setting-title-span">
          <span className="setting-title-span">Settings</span>
          <span
            className="setting-close-span"
            style={{
              right: "10px",
              position: "absolute",
              cursor: "pointer",
              fontSize: "30px",
              zIndex: "888",
              color: "red",
            }}
            onClick={onclose}
          >
            &times;
          </span>
        </span>
        <div className="setting-pop-body">
          <div className="setting-body-div1">
            <ul className="setting-pop-ul">
              <Link to="/vieworgprofile">
                <li className="setting-pop-li">
                  <span className='span'>
                    <AiFillBuild />
                    <span className='user-setting-a'>Organization Profile </span>
                  </span>
                </li>
              </Link>

              <Link to="/viewdep">
                <li className="setting-pop-li">
                  <span className='span'>
                    <CiCircleList />
                    <span> Department</span>
                  </span>
                </li>
              </Link>

              <Link to="/viewdephead">
                <li className="setting-pop-li">
                  <span className='span'>
                    <FiUserCheck />
                    <span>  Department Head</span>
                  </span>
                </li>
              </Link>

              <Link to="/viewdeptl">
                <li className="setting-pop-li">
                  <span className='span'>
                    <FiUser />
                    <span> Department TL</span>
                  </span>
                </li>
              </Link>

              <Link to="/viewdesignation">
                <li className="setting-pop-li">
                  <span className='span'>
                    <FiAnchor />
                    <span> Designation </span>
                  </span>
                </li>
              </Link>



              <Link to="/holiday">
                <li className="setting-pop-li">
                  <span className='span'>
                    {/* <AiFillMoneyCollect /> */}
                    <FiBookOpen />
                    <span> Hoilday </span>
                  </span>
                </li>
              </Link>
              <Link to="/changepass">
                <li className="setting-pop-li">
                  <span className='span'>
                    <FiKey />
                    <span> Change Password</span>
                  </span>
                </li>
              </Link>
              <Link to="/privacy">
                <li className="setting-pop-li">
                  <span className='span'>
                    <FiLock />
                    <span> Privacy & Security</span>
                  </span>
                </li>
              </Link>
              {/* <Link to="../download-app">
                      <li className="setting-pop-li">
                        <span className='span'>
                          <FiDownload />
                          <span>Download App </span>
                        </span>
                      </li>
                    </Link> */}
              {/* <Link to="/feedback">
                      <li className="setting-pop-li">
                        <span className='span'>
                          <FiCommand />
                          <span>Feedback</span>
                        </span>
                      </li>
                    </Link> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSettings