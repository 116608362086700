import React, { useState, useEffect } from 'react';
import DatepickerComp from '../../Component/DatepickerComp';
import './Holiday.css';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';

import { CompanyApi } from '../../../Config/CommonApi';
import { AddHoliday } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';

const Addholiday = ({ show, close, sendDataToParent }) => {
  const user_details = getUserDetails();

  const [total_days, setNumberofDays] = useState(0);
  const [holiday_from_date, setFromDate] = useState('');
  const [holiday_end_date, setToDate] = useState('');
  const [companyData, setCompanyData] = useState([]);
  const [loader, setloader] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [errors, setErrors] = useState({}); // To track validation errors

  const [Formdata, setFormData] = useState({
    reason: '',
    holiday_from_date: '',
    holiday_end_date: '',
    total_days: 0,
    user: user_details.id,
  });

  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await CompanyApi();
      setCompanyData(data);
    };

    fetchCompanyData();
  }, []);

  const onchangeinput = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const options = companyData
    .filter((company) => company.value !== 'all')
    .map((company) => ({
      label: company.label,
      value: company.value,
    }));

  useEffect(() => {

    setFormData((prevData) => ({
      ...prevData,
      holiday_from_date,
      holiday_end_date,
      total_days: total_days,
    }));
  }, [holiday_from_date, holiday_end_date, total_days, options]);




  const handleChange = (selected) => {
    setSelectedCompanies(selected);
  };

  const validateForm = () => {
    const errors = {};
    if (!Formdata.reason) {
      errors.reason = 'Holiday reason is required';
    }
    if (!holiday_from_date) {
      errors.holiday_from_date = 'Holiday  date is required';
    }

    if (selectedCompanies.length === 0) {
      errors.selectedCompanies = 'Please select at least one company';
    }
    if (total_days <= 0) {
      errors.total_days = 'Total days must be greater than 0';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Check if there are no errors
  };

  const handlesubmitbutton = async () => {
    if (!validateForm()) {
      return; // Stop submission if form is invalid
    }

    const selectedcompany = selectedCompanies.map((company) => company.value);
    const data = {
      company: selectedcompany,
      ...Formdata,
    };

    try {
      setloader(true)
      const response = await AddHoliday(data);
      if (response) {
        sendDataToParent(response.data.holiday_details[0]);
        toast.success('Holiday Added Successfully!', {
          position: 'top-center',
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast',
        });
        close();
        setFormData({
          reason: '',
          holiday_from_date: '',
          holiday_end_date: '',
          total_days: 0,
          user: user_details.id,
        });
        setSelectedCompanies([]);
        setFromDate('');
        setToDate('');
        setNumberofDays(0);
      }
    } catch (error) {
      if (error.response) {
        const newErrors = {};
        newErrors.response_error = error.response.data.data;
        setErrors(newErrors);
      } else {
        toast.error('Failed. Please try again.', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,

        });
        const newErrors = {
          response_error: [],
        };
        for (const key in error.response.data.errors) {
          if (error.response.data.errors.hasOwnProperty(key)) {
            newErrors.response_error.push(`${error.response.data.errors[key]}`);
          }
        }
        setErrors(newErrors);
        setFormData((state) => ({
          ...state,
        }));
      }
      // Handle error here (optional)
    } finally {
      setloader(false)

    }
  };

  if (!show) return null;

  return (
    <div className='popup-section'>
      <div className='popup-container-holiday'>
        <span className='uploadexcel-close' onClick={close}>
          &times;
        </span>

        <div className='popup-container-sub'>
          <div className='addleave-heading'>
            <p>Add Holiday</p>
          </div>

          <div className='addleave-strightline'></div>
          <div className='popup-content-viewing-adddepdes'>
            <div className='addholiday-form-cont'>
              <div className='popup-input-hdtl addholi-flex'>
                <div className='addholi-input flex-holiday'>
                  <label>
                    Holiday Date<span className='registeration-mandatory'> *</span>{' '}
                  </label>

                  <DatepickerComp
                    setNumberOfDays={setNumberofDays}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                  />
                  {errors.holiday_from_date && (
                    <span className='error-message-view'>{errors.holiday_from_date}</span>
                  )}

                </div>

                <div className='addholi-input'>
                  <label>
                    No Of Days<span className='registeration-mandatory'> *</span>{' '}
                  </label>
                  <input value={total_days} readOnly />
                  {errors.total_days && (
                    <span className='error-message-view'>{errors.total_days}</span>
                  )}
                </div>
              </div>

              <div className='addholi-input-multiselect'>
                <label>
                  Select Company<span className='registeration-mandatory'> *</span>{' '}
                </label>
                <MultiSelect
                  options={options}
                  value={selectedCompanies}
                  onChange={handleChange}
                  labelledBy='Select Company'
                />
                {errors.selectedCompanies && (
                  <span className='error-message-view'>{errors.selectedCompanies}</span>
                )}
              </div>

              <div className='popup-input-hdtl'>
                <label>
                  Holiday Reason<span className='registeration-mandatory'> *</span>{' '}
                </label>
                <div className='addholi-input'>
                  <textarea
                    placeholder='Enter the reason for the holiday'
                    onChange={onchangeinput}
                    name='reason'
                  />
                  {errors.reason && (
                    <span className='error-message-view'>{errors.reason}</span>
                  )}
                </div>
              </div>
              {errors.response_error && (
                <div className="error-message-view">{errors.response_error}</div>

              )}
              <br />
              <div className='popup-button'>
                <button onClick={handlesubmitbutton} disabled={loader}>
                  {loader ? <span className='spinner'></span> : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addholiday;
