import React, { useState, useEffect, useCallback } from 'react';
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import { getUserDetails } from '../../../Services/Storage';
import { Deletedeptlapi, FetchDepartmenttl } from '../../../Config/api';
import pageloader from '../../../Loader/pageloading/Pageloader';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import ReactPaginate from "react-paginate";
import DeleteAlert from '../../popup/DeleteAlert';
import Adddepartmenttl from './Adddepartmenttl';
import Editdepartmenttl from './Editdepartmenttl';
import { Store } from '../../../Store';
import { deleteDepartmenttl } from '../../../Slice/DepartmentTlSlice';
import Deleteconfirmation from '../../Component/Deleteconfirmation';

const Viewdepartmenttl = () => {
    const user_details = getUserDetails();
    const [isadddepopen, setadddepopen] = useState(false);
    const [departmenttl, setdepartmentTLList] = useState([])
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [deleteComponentId, setDeleteComponentId] = useState(null);
    const [opendeleteconf, setdeleteconf] = useState(false)
    const [editData, setEditData] = useState([]);
    const [iseditopen, seteditopen] = useState(false)
    const [loading, setIsloading] = useState(false);
    const [totaldedcount, setdedcount] = useState(0)
    const per_page = 10;
    const [currentpage, setCurrentPage] = useState(1);
    const [search, setSearchword] = useState("");
    const [deleteComponentData, setDeleteComponentData] = useState([]); // New state to hold data

    const currentPageNo = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    const handleSearch = (event) => {
        setSearchword(event.target.value);
    }

    const editdepheadButton = (componentid) => {
        const updatedList = departmenttl.filter((component) =>
            component.row_id === componentid
        );
        if (updatedList) {
            setEditData(updatedList[0]);
            seteditopen(true);

        }
    }
    const handleUpdateData = (data) => {
        const updatedList = departmenttl.map((comp) =>
            comp.row_id === data.row_id ? data : comp
        );
        setdepartmentTLList(updatedList);
    }
    const closeeditlevae = () => {
        seteditopen(false)
    }
    const openadddephd = () => {
        setadddepopen(true);
    }
    const closeadddephd = () => {
        setadddepopen(false);
    }

    const Fetchdepartmentapi = useCallback(async () => {
        try {
            setIsloading(true);

            const response = await FetchDepartmenttl(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}`);
            if (response) {
                setdedcount(response.data.page_context.total)
                setdepartmentTLList(response.data.get_depart_tl);

            }
        } catch (error) {

        } finally {
            setIsloading(false)

        }
    }, [currentpage, per_page, search, user_details.id])
    useEffect(() => {
        Fetchdepartmentapi();
    }, [Fetchdepartmentapi])
    const confirmDelete = () => {
        if (deleteComponentId) {
            deletesalaryComponent(deleteComponentId);
            closeConfirmationModal();
        }
    };
    const deletesalaryComponent = async (componentId) => {
        try {
            const response = Deletedeptlapi(componentId);
            if (response && (await response).status === 200) {
                const updatedList = departmenttl.filter((component) => component.row_id !== componentId);
                setdepartmentTLList(updatedList);
                Store.dispatch(deleteDepartmenttl(componentId))
            }
        }
        catch (error) {
            if (error.response && error.response.status === 422) {
                setDeleteComponentData(error.response.data.data);

                setdeleteconf(true)

            }
        }
    }
    // const departmenthead = departmentHeadList.get_depart_head;
    const showConfirmationModalDelete = (delid) => {
        setDeleteComponentId(delid);
        setConfirmationModalOpen(true);

    }
    const closeConfirmationModal = () => {
        setDeleteComponentId(null);
        setConfirmationModalOpen(false);
    };
    const handleDataFromChild = (data) => {
        setdepartmentTLList((prev) => [
            data,
            ...prev,
        ]);
    };

    const closedeleteconfirm = () => {
        setdeleteconf(false)
        setDeleteComponentData([])
    }
    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Department TL</p>
                                    <span className='header-search-add-parent'>
                                        <div className='pages-serach-view'>
                                            <CiSearch className='page-search-icon' />
                                            <input className='pages-serach' onChange={handleSearch} placeholder='Search Department TL' type='search' />
                                        </div>
                                        <button className='pages-addempbutton-leave' onClick={openadddephd}>Add Department TL</button>
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className='pages-maincontent'>
                                {loading ? (
                                    <div className="loader-container">
                                        <img src={pageloader.page_loader} alt="Loading..." />
                                    </div>
                                )
                                    : (
                                        <table className='pages-table'>
                                            <thead>
                                                <tr>
                                                    <th></th>

                                                    <th>Sl No</th>
                                                    <th>Department</th>
                                                    <th>TL Name</th>
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {departmenttl && departmenttl.length > 0 ? (
                                                    departmenttl.map((departmenttl, index) => (
                                                        <tr key={departmenttl.row_id}>
                                                            <td></td>

                                                            <td>{index + 1}</td>
                                                            <td>{departmenttl.depart_name}</td>
                                                            <td>{departmenttl.depart_tl_name}</td>
                                                            <td>{departmenttl.user !== null ? (
                                                                <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                                                    <CiEdit onClick={() => editdepheadButton(departmenttl.row_id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                                                    <CiTrash onClick={() => showConfirmationModalDelete(departmenttl.row_id)} style={{ color: "red", cursor: "pointer" }} />
                                                                </span>
                                                            ) : null}</td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="no-data-table">

                                                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </table>
                                    )}
                                <div style={{ float: "right" }} id="pagination_link"></div>
                                <div style={{ padding: "20px" }}>
                                    {totaldedcount > per_page ? (
                                        <>
                                            <ReactPaginate
                                                pageCount={Math.ceil(totaldedcount / per_page)}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                onPageChange={currentPageNo}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </>
                                    ) : (
                                        <> </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Adddepartmenttl
                show={isadddepopen}
                close={closeadddephd}
                sendDataToParent={handleDataFromChild}

            />
            <Editdepartmenttl
                editData={editData}
                updateData={handleUpdateData}
                show={iseditopen}
                close={closeeditlevae}
            />
            <DeleteAlert
                isOpen={isConfirmationModalOpen}
                message="Really, would you like to remove this Component?"
                onCancel={closeConfirmationModal}
                onConfirm={confirmDelete}
            />
            <Deleteconfirmation
                show={opendeleteconf}
                close={closedeleteconfirm}
                data={deleteComponentData} // Pass data to the confirmation popup

            />

        </div>
    );
}

export default Viewdepartmenttl;
