import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const salarySlice = createSlice({
    name: "salary component",
    initialState,
    reducers: {
        addSalaryComponent(state, action) {
            state.push(action.payload);
        },
        setSalaryComponent(state, action) {
            return action.payload;
        },
        updateSalaryComponent(state, action) {
            const updatedComponent = action.payload;

            return state.map((component) =>
                component.id === updatedComponent.id
                    ? { ...component, ...updatedComponent }
                    : component
            );
        }

    }
})

export const { addSalaryComponent, setSalaryComponent, updateSalaryComponent } = salarySlice.actions;
export default salarySlice.reducer;