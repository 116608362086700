import React, { useEffect, useState } from 'react'
import './Notification.css'
import { Viewnotification } from '../../../Config/api'
import { getUserDetails } from '../../../Services/Storage';
import userdemoimg from '../../../Images/Pagesimg/userdemo';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
const Notification = ({ show, close }) => {
  const user_details = getUserDetails();
  const [notification, setnotification] = useState([]);
  const [isloading, setloading] = useState(false)

  useEffect(() => {
    const fetchnotification = async () => {
      if (user_details.role === 'Company') {


        try {
          setloading(true)
          const response = await Viewnotification(`?register_id=${user_details.id}`);
          setnotification(response.data.get_notification);
        } catch {

        } finally {
          setloading(false)

        }
      }
    }
    fetchnotification();

  }, [user_details.id, user_details.role]);


  if (!show) return null;
  return (
    <div className='notification_main'>
      <div className='notification_mainsub'>
        <div className='notification_container'>
          <div className='notification_header'>
            <div>
              Notifications
            </div>
            <div className='close_bt_popup' onClick={close}>
              <p>x</p>
            </div>
          </div>
          <div className='stright_line'></div>
          {isloading ? (
            <div className="loader-container">
              <img src={pageloader.page_loader} alt="Loading..." />
            </div>
          ) : (
            <>
              {notification.length === 0 ? (
                <div className="no-data-table">
                  <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />

                </div>
              ) : (

                notification.map((notificlist, index) => (
                  <div className='notification_content' key={index}>
                    <div className='notification_view'>
                      <div className='notification_viewsub'>

                        <div className='notification_viewimg'>
                          <img src={userdemoimg.demo_img} alt='img' />

                        </div>
                        <div>
                          <p>{notificlist.content}</p>
                        </div>

                      </div>

                    </div>
                    <div className='stright_line'></div>

                  </div>
                ))
              )}

            </>
          )
          }




        </div>
      </div>


    </div>
  )
}

export default Notification
