import React, { useState, useEffect, useCallback } from 'react';
import { CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import { CiEdit } from 'react-icons/ci';
import Adddesignation from './Adddesignation';
import { getUserDetails } from '../../../Services/Storage';
import { DeletedesignationcompApi, FetchDesigApi } from '../../../Config/api';
import Editdesignation from './Editdesignation';
import DeleteAlert from '../../popup/DeleteAlert';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import { Store } from '../../../Store';
import { deleteDesignationComp } from '../../../Slice/designationSlice';
import Deleteconfirmation from '../../Component/Deleteconfirmation';

const Viewdesignation = () => {
    const user_details = getUserDetails();
    const [isadddepopen, setadddepopen] = useState(false);
    const [designation, setdesignation] = useState([]);
    const [editData, setEditData] = useState([]);
    const [iseditopen, seteditopen] = useState(false)
    const [deleteComponentId, setDeleteComponentId] = useState(null);
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [totaldedcount, setdedcount] = useState(0)
    const per_page = 10;
    const [currentpage, setCurrentPage] = useState(1);
    const [search, setSearchword] = useState("");
    const [loading, setIsloading] = useState(false);
    const [deleteComponentData, setDeleteComponentData] = useState([]); // New state to hold data
    const [opendeleteconf, setdeleteconf] = useState(false)

    const currentPageNo = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    const handleSearch = (event) => {
        setSearchword(event.target.value);
    }
    const openadddep = () => {
        setadddepopen(true);
    }
    const closeaddleave = () => {
        setadddepopen(false);
    }
    const Fetchdesignation = useCallback(async () => {
        try {
            setIsloading(true);

            const response = await FetchDesigApi(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}`)
            if (response) {
                setdesignation(response.data.get_designation || [])
                setdedcount(response.data.page_context.total)

            }
        } catch (error) {

        } finally {
            setIsloading(false)
        }
    }, [currentpage, search, user_details.id, per_page]);

    useEffect(() => {
        Fetchdesignation();
    }, [Fetchdesignation]);

    const handleDataFromChild = (data) => {
        setdesignation((prev) => [
            data,
            ...prev,
        ])
    }
    const editdesigButton = (componentid) => {
        const updatedList = designation.filter((component) =>
            component.id === componentid
        );
        if (updatedList) {
            setEditData(updatedList[0]);
            seteditopen(true);

        }
    }
    const handleUpdateData = (data) => {
        const updatedList = designation.map((comp) =>
            comp.id === data.id ? data : comp
        );
        setdesignation(updatedList);
    }
    // const designation = designationList.get_designation;
    const closedesign = () => {
        seteditopen(false)
    }

    const confirmDelete = () => {
        if (deleteComponentId) {
            deletesalaryComponent(deleteComponentId);
            closeConfirmationModal();
        }
    };
    const deletesalaryComponent = async (componentId) => {
        try {
            const response = await DeletedesignationcompApi(componentId);
            if (response) {
                const updatedList = designation.filter((component) => component.id !== componentId);

                setdesignation(updatedList);
                Store.dispatch(deleteDesignationComp(componentId));

            }
        }
        catch (error) {
            if (error.response && error.response.status === 422) {
                setDeleteComponentData(error.response.data.data);

                setdeleteconf(true)

            }
        }
    }
    const showConfirmationModalDelete = (delid) => {
        setDeleteComponentId(delid);
        setConfirmationModalOpen(true);

    }
    const closeConfirmationModal = () => {
        setDeleteComponentId(null);
        setConfirmationModalOpen(false);
    };
    const closedeleteconfirm = () => {
        setdeleteconf(false)
        setDeleteComponentData([])
    }
    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Designation
                                    </p>
                                    <span className='header-search-add-parent'>
                                        <div className='pages-serach-view'>
                                            <CiSearch className='page-search-icon' />
                                            <input className='pages-serach' onChange={handleSearch} placeholder='Search Designation ' type='search' />
                                        </div>
                                        <button className='pages-addempbutton' onClick={openadddep}>Add Designation</button>
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className='pages-maincontent'>
                                {loading ? (
                                    <div className="loader-container">
                                        <img src={pageloader.page_loader} alt="Loading..." />
                                    </div>
                                )
                                    : (
                                        <table className='pages-table'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Sl No</th>
                                                    <th>Designation</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {designation && designation.length > 0 ? (

                                                    designation.map((designation, index) => (
                                                        <tr key={designation.id}>
                                                            <td></td>
                                                            <td>{index + 1}</td>
                                                            <td>{designation.designation}</td>
                                                            <td>{designation.user !== null ? (
                                                                <span style={{ display: "flex", flexDirection: "row", gap: "20px", fontSize: "14px", alignItems: "center" }}>
                                                                    <CiEdit onClick={() => editdesigButton(designation.id)} style={{ color: "#2a7ac7", cursor: "pointer" }} />
                                                                    <CiTrash onClick={() => showConfirmationModalDelete(designation.id)} style={{ color: "red", cursor: "pointer" }} />
                                                                </span>
                                                            ) : null}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="no-data-table">

                                                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />



                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </table>
                                    )}
                                <div style={{ float: "right" }} id="pagination_link"></div>
                                <div style={{ padding: "20px" }}>
                                    {totaldedcount > per_page ? (
                                        <>
                                            <ReactPaginate
                                                pageCount={Math.ceil(totaldedcount / per_page)}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                onPageChange={currentPageNo}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </>
                                    ) : (
                                        <> </>
                                    )}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Adddesignation
                show={isadddepopen}
                close={closeaddleave}
                sendDataToParent={handleDataFromChild}

            />
            <Editdesignation
                editData={editData}
                updateData={handleUpdateData}
                show={iseditopen}
                close={closedesign}
            />
            <DeleteAlert
                isOpen={isConfirmationModalOpen}
                message="Really, would you like to remove this Component?"
                onCancel={closeConfirmationModal}
                onConfirm={confirmDelete}
            />
            <Deleteconfirmation
                show={opendeleteconf}
                close={closedeleteconfirm}
                data={deleteComponentData} // Pass data to the confirmation popup

            />
        </div>
    );
}

export default Viewdesignation;
