import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employees: []
};

const employeeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {

    addEmployees(state, action) {
      // Optional future functionality
    },

    setEmployees(state, action) {
      // Check if the payload is an array
      if (Array.isArray(action.payload)) {
        state.employees = action.payload;
      } else if (typeof action.payload === 'object' && action.payload !== null) {
        // If the payload is a single object, wrap it in an array
        state.employees = [action.payload];
      } else {
      }

    },

    setUpdateEmployee(state, action) {
      const { updatedData, leaveData } = action.payload;
      if (!Array.isArray(state.employees)) {
        return;
      }
      const employeeIndex = state.employees.findIndex(emp => emp.work_details.id === updatedData.id);
      if (employeeIndex !== -1) {
        state.employees[employeeIndex] = {
          ...state.employees[employeeIndex],
          work_details: {
            ...state.employees[employeeIndex].work_details,
            ...updatedData
          },
          eligible_leave_details: {
            ...state.employees[employeeIndex].eligible_leave_details,
            ...leaveData
          }
        };

      } else {
      }
    },

    setUpdatePersonalInfo(state, action) {
      const { updatedData } = action.payload;
      if (!Array.isArray(state.employees)) {
        return;
      }
      const employeeIndex = state.employees.findIndex(emp => emp.register_id === updatedData.id);
      if (employeeIndex !== -1) {
        state.employees[employeeIndex] = {
          ...state.employees[employeeIndex],
          register_details: {
            ...state.employees[employeeIndex].register_details,
            ...updatedData
          }
        };
      } else {
      }
    },

    setUpdatePaymentInfo(state, action) {
      const { updatedData } = action.payload;
      if (!Array.isArray(state.employees)) {
        return;
      }
      const employeeIndex = state.employees.findIndex(emp => emp.payment_details.id === updatedData.id);
      if (employeeIndex !== -1) {
        state.employees[employeeIndex] = {
          ...state.employees[employeeIndex],
          payment_details: {
            ...state.employees[employeeIndex].payment_details,
            ...updatedData
          }
        };
      } else {
      }
    },
    setUpdateSalaryInfo(state, action) {
      const { updatedData } = action.payload;
      if (!Array.isArray(state.employees)) {
        return;
      }
      const employeeIndex = state.employees.findIndex(emp => emp.salary_details.id === updatedData.id);
      if (employeeIndex !== -1) {
        state.employees[employeeIndex] = {
          ...state.employees[employeeIndex],
          salary_details: {
            ...state.employees[employeeIndex].salary_details,
            ...updatedData
          }
        };
      } else {
      }
    }
  }
});

export const {
  addEmployees,
  setEmployees,
  setUpdateEmployee,
  setUpdatePersonalInfo,
  setUpdatePaymentInfo,
  setUpdateSalaryInfo
} = employeeSlice.actions;

export default employeeSlice.reducer;
