const leaveStatusText = (status) => {
  switch (status) {
    case '0':
      return { text: 'Pending', color: 'orange' };
    case '1':
      return { text: 'Approved', color: 'green' };
    case '2':
      return { text: 'Rejected', color: 'red' };
    case '3':
      return { text: 'Hold', color: 'blue' };
    default:
      return { text: 'None', color: 'gray' };
  }
};

export default leaveStatusText;

