import React from 'react'
import Action from '../../Images/Action/action';

const Confirmationwidbtn = ({ close, open, onConfirm, data }) => {

  if (!open) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className='modal-content-close' onClick={close}>
          X
        </div>
        <h3 className='modal-content-h3'>Alert</h3>
        <div className='modal-content-container'>

          <div className='modal-content-imgview'>

            <img src={Action.error_img} alt='img' />
          </div>
        </div>

        <p className='modal-content-p'>{data.alert}</p>
        <div className="modal-buttons">

          <button onClick={onConfirm} className="confirm-button">Confirm</button>
        </div>
      </div>
    </div>

  )
}

export default Confirmationwidbtn