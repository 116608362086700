import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const leaveComponentSlice = createSlice({
    name: 'Leave Component',
    initialState,
    reducers: {
        setLeaveComponent(state, action) {
            return action.payload;
        },
        addLeavecomponent(state, action) {
            state.push(action.payload);
        },
        updateLeaveComponent(state, action) {
            const updatedComponent = action.payload;

            return state.map((component) =>
                component.id === updatedComponent.id
                    ? { ...component, ...updatedComponent }
                    : component
            );
        }
    }
});

export const { setLeaveComponent, addLeavecomponent, updateLeaveComponent } = leaveComponentSlice.actions;
export default leaveComponentSlice.reducer; 