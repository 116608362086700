import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Sidebar/Sidebar'
import Header from '../../../Header/Header'
import { useNavigate } from 'react-router-dom'
import "../../Style/Employeeform.css";
import '../../Style/Addemp.css';
import { CiEdit } from 'react-icons/ci'
import './Viewsalarydetails.css'
import { useSelector } from 'react-redux'

const Viewsalarydetails = () => {
   const navigate = useNavigate();

   const [user_id, setUserId] = useState("");
   const employeeDetails1 = useSelector((state) => state.employees);
   const [employeeDetails, setEmployeeDetails] = useState(null);
   useEffect(() => {
      if (employeeDetails1 && employeeDetails1.employees.length > 0) {
         setEmployeeDetails(employeeDetails1.employees[0]);
         //setEmpName(employeeDetails1.employees[0].register_details.name);
         setUserId(employeeDetails1.employees[0].register_id);
      }
   }, [employeeDetails1]);



   useEffect(() => {
      // fetchEditEmployeeList();
   }, [user_id]);

   const overview_view = () => {
      navigate('/vieweditdetails', { state: { user_id } });
   }
   const salary_view = () => {
      navigate('/viewsalarydetails', { state: { user_id } });
   }
   const annualCTC = employeeDetails?.salary_details?.annual_ctc || "N/A";
   const monthlySalary = employeeDetails?.salary_details?.monthly_salary || "N/A";
   const editsalarydetail = () => {

      navigate('/editsalarydetails')
   }
   const handleaddemploye = () => {
      navigate('/addemployee')
   }
   return (
      <div className='index-container'>
         <Header />
         <div className='index-content-parent'>
            <div className='index-content'>
               <Sidebar />
               <div className='index-content-sub'>
                  <div className='index-main'>
                     <div className='addemp-subheader'>
                        <div className='addemp-subheader-sub'>
                           <div className='vieweditdet-subheader'>
                              <p>{employeeDetails && employeeDetails.register_details ? employeeDetails.register_details.name : "N/A"}</p>
                              <div className='vieweditdet-top-dropdown'>
                                 <button onClick={handleaddemploye} >Add Employee</button>
                              </div>
                           </div>
                           <div className='addemp-subheader2'>


                              <div className="vieweditdet-stepper">
                                 <div className="vieweditdet-step-indicator">
                                    <span className='vieweditdet-redirect-link' onClick={overview_view}>
                                       <div className='vieweditdet-step '>
                                          Overview
                                       </div>
                                    </span >
                                    <span className='vieweditdet-redirect-link2' onClick={salary_view}>
                                       <div className='vieweditdet-step vieweditdet-selected'>
                                          Salary Details
                                       </div>
                                    </span>
                                 </div>


                              </div>

                           </div>

                        </div>
                     </div>
                     <div className='addemp-line'></div>
                     <div className='addemp-maincontent-sub'>
                        <div className='addemp-form form-padding-sal'>

                           <div className='addemp-form-sub-slary'>
                              <div className='addemp-form-sub-main'>
                                 <div className='salarydetails-card'>
                                    <div className='vieweditdet-edit-icon' onClick={editsalarydetail}>
                                       <CiEdit />
                                    </div>
                                    <div className='salarydetails-input-row'>

                                       <div className='salarydetails-input-rowsub'>

                                          <div className='salarydetails-input-group'>

                                             <p>Annual CTC <span className='emppersonalinfo-mandatory'>*</span></p>
                                          </div>
                                          <div className='salarydetails-input-group font-view-salary' >

                                             ₹{annualCTC}
                                          </div>

                                       </div>
                                    </div>


                                    <div className='salarydetails-earning-dect '>



                                       <table>
                                          <tr>
                                             <th>SALARY COMPONENTS</th>
                                             <th>CALCULATION TYPE</th>
                                             <th>MONTHLY AMOUNT</th>
                                             <th>ANNUAL AMOUNT</th>
                                          </tr>
                                          <p >Earnings:</p>

                                          {
                                             employeeDetails?.salary_details?.salary_list
                                                .filter(sal_component => sal_component.earning_deduction === 'earning' && sal_component.isChecked)
                                                .map((sal_component, index) => (
                                                   <tr key={index}>
                                                      <td>{sal_component.component_name}</td>
                                                      <td className='view-errorsal-input'>
                                                         <span>{sal_component.calculation_type}</span><br />
                                                         <input type="text" value={sal_component.amount_type} name='special_all' placeholder="Enter value" />
                                                      </td>
                                                      <td>{sal_component.monthly}</td>
                                                      <td>{sal_component.yearly}</td>
                                                   </tr>
                                                ))
                                          }



                                          <p>Deduction:</p>
                                          {
                                             employeeDetails?.salary_details?.salary_list
                                                .filter(sal_component => sal_component.earning_deduction === 'deduction' && sal_component.isChecked)
                                                .map((sal_component, index) => (
                                                   <tr key={index}>
                                                      <td>{sal_component.component_name}</td>
                                                      <td className='view-errorsal-input'>
                                                         <span>{sal_component.calculation_type}</span><br />
                                                         <input type="text" value={sal_component.amount_type} name='special_all' placeholder="Enter value" />

                                                      </td>
                                                      <td>{sal_component.monthly}</td>
                                                      <td>{sal_component.yearly}</td>
                                                   </tr>
                                                ))
                                          }
                                          <tr className='salarydetails-last-col'>
                                             <td>Cost to Company</td>
                                             <td></td>
                                             <td className='font-view-common'>₹{monthlySalary}</td>
                                             <td className='font-view-common'>₹{annualCTC}</td>
                                          </tr>
                                       </table>

                                    </div>
                                 </div>

                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Viewsalarydetails