import React from 'react';
import '../../Assets/css/delete_alert.css'

const DeleteAlert = ({ isOpen, message, reortnm, onCancel, onConfirm }) => {


  if (!isOpen) return null;

  return (
    <div className="popup-container-delete" id="popup">
      <div className="popup-content-delete">
        <div className="delete-alert-content">
          <h4>{message}</h4>
        </div>
        <div className="delete-alert-button">
          <div className="delete-alert-button-div1">
            {reortnm === "deactive" ? (
              <span
                className="delete-alert-button-div1-delete"
                onClick={onConfirm}
              >
                {" "}
                Active
              </span>
            ) : (
              <span
                className="delete-alert-button-div1-delete"
                onClick={onConfirm}
              >
                {" "}
                Yes
              </span>
            )}

            <span
              className="delete-alert-button-div1-cancel"
              onClick={onCancel}
            >
              No
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAlert;
