const websiteImages={
    about : "./Images/website_images/about.png",
    appstore : "./Images/website_images/appstore.png",
    image1 : "./Images/website_images/img1.png",
    contact : "./Images/website_images/contact.png",
    image2 : "./Images/website_images/img2.png",
    playstore : "./Images/website_images/playstore.png",
    attend     :"./Images/website_images/attend.png",
    emplo      :"./Images/website_images/Emplo.png",
    payroll     : "./Images/website_images/payroll.png",
    About_tt     : "./Images/website_images/about_att.png"

    
}

export default websiteImages;