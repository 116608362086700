import React, { useEffect, useState } from 'react';
import './Index.css';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom'
import Header from '../Header/Header';
import Siderbar from '../Sidebar/Sidebar';
import CalendarDashboard from './CalendarDashboard';
import DashboardImages from '../../Images/DashboardImages/DashboardImages';
import { FetchLeavecompApi, StaffAllDetailsApi, Viewdashboardcount, Viewnewemployee, Viewpendingleave, viewSalaryComponentApi, Viewstaffcount, Viewstaffpayrolllst, Viewstaffpendingstafflst } from '../../Config/api';
import { getUserDetails, storeStaffDetails } from '../../Services/Storage';
import { Store } from '../../Store';
import { setLeaveComponent as setLeaveComponentRedux } from '../../Slice/leaveComponentSlice';
import { setSalaryComponent as setSalaryComponentRedux } from '../../Slice/salaryComponentSlice';
import pageloader from '../../Loader/pageloading/Pageloader';
import monthNames from '../Component/Monthview';
const Dashboard = () => {
  const navigate = useNavigate();

  const user_details = getUserDetails();
  const [userName, setUserName] = useState("User");
  const [dashboardct, setdashboardct] = useState([]);
  const [dashboardpendinglev, setdashboardpendinglev] = useState([]);
  const [dashboardemplnew, setdashboardemplnew] = useState([]);
  const [loader, setloader] = useState(false);
  const [staffdetcount, setstaffdetcount] = useState([]);
  const [fthpending, setfthpending] = useState([]);
  const [payrllstaff, setpayrollstaff] = useState([]);
  useEffect(() => {
    const fetchLeaveComponent = async () => {
      try {
        const response = await FetchLeavecompApi(`?register_id=${user_details.id}`);
        Store.dispatch(setLeaveComponentRedux(response.data.get_leave_component));
      } catch (error) {
      }
    };
    const fetchsalaryComponent = async () => {
      try {
        const response = await viewSalaryComponentApi(`?register_id=${user_details.id}`);
        Store.dispatch(setSalaryComponentRedux(response.data.get_salary_component_earning));
      } catch (error) {
      }
    }
    const fetchStaffAllDetails = async () => {
      if (user_details.role === 'staff') {
        try {
          const response = await StaffAllDetailsApi(`?register_id=${user_details.id}&admin_id=${user_details.user_id}`);
          storeStaffDetails(response.data.staff_all_details);
          console.log(response.data.staff_all_details.register_details)
          setUserName(response.data.staff_all_details.register_details.name);
        } catch (error) {
        }
      }
    }

    const fetchdashboardcount = async () => {
      if (user_details.role === 'Company') {
        try {
          const response = await Viewdashboardcount(`?register_id=${user_details.id}&admin_id=${user_details.id}`)
          setdashboardct(response.data)
        } catch (error) {
        }
      }
    }
    const fetchdashboardcountstaff = async () => {
      if (user_details.role === 'staff') {

        try {
          const response = await Viewstaffcount(`?register_id=${user_details.id}&admin_id=${user_details.id}`)
          setstaffdetcount(response.data);

        } catch (error) {
        }
      }
    }
    const fetchdashboardpendingstaff = async () => {
      if (user_details.role === 'staff') {

        try {
          const response = await Viewstaffpendingstafflst(`?register_id=${user_details.id}&admin_id=${user_details.id}`)
          setfthpending(response.data.emp_leave_view);

        } catch (error) {
        }
      }
    }
    const fetchdashboardpayrollstaff = async () => {
      if (user_details.role === 'staff') {
        setloader(true);

        try {
          const response = await Viewstaffpayrolllst(`?register_id=${user_details.id}&admin_id=${user_details.id}`)
          setpayrollstaff(response.data.emp_salary_data);

        } catch (error) {
        } finally {
          setloader(false);
        }
      }
    }
    const fetchdashboardleave = async () => {
      if (user_details.role === 'Company') {
        try {
          const response = await Viewpendingleave(`?register_id=${user_details.id}&admin_id=${user_details.id}`)
          setdashboardpendinglev(response.data.emp_leave_view)
          // setdashboardct(response.data)
        } catch (error) {
        }
      }
    }
    const fetchdashboardemployee = async () => {

      if (user_details.role === 'Company') {
        setloader(true)
        try {
          const response = await Viewnewemployee(`?register_id=${user_details.id}&admin_id=${user_details.id}`)
          setdashboardemplnew(response.data.filter_employees);
          // setdashboardct(response.data)
        } catch (error) {
        } finally {
          setloader(false)

        }
      }
    }
    fetchdashboardemployee();
    fetchdashboardleave();
    fetchdashboardcount();
    fetchLeaveComponent();
    fetchsalaryComponent();
    fetchdashboardcountstaff();
    fetchStaffAllDetails();
    fetchdashboardpendingstaff();
    fetchdashboardpayrollstaff();
  }, [user_details.id, user_details.role, user_details.user_id]);

  const navigateedit = () => {
    navigate('/viewprofile')
  }

  const navigateleave = () => {
    navigate('/viewleave')

  }
  const navigateemploye = () => {
    navigate('/viewattendance')

  }

  const navigateeditstaff = () => {
    navigate('/staffprofile')
  }

  const navigateleavestaff = () => {
    navigate('/staffLeave')

  }

  const payrollstaff = () => {
    navigate('/viewstaffpayroll')
  }

  const convertToFractionalYears = (timeString) => {
    const yearsMatch = timeString.match(/(\d+)\s+years?/);
    const monthsMatch = timeString.match(/(\d+)\s+months?/);

    const years = yearsMatch ? parseInt(yearsMatch[1], 10) : 0;
    const months = monthsMatch ? parseInt(monthsMatch[1], 10) : 0;
    const fractionalYears = years + (months / 12);

    return fractionalYears.toFixed(1);
  };

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Siderbar />
          <div className='index-content-sub'>
            {/* start */}
            {loader ? (
              <div className="loader-container">
                <img src={pageloader.page_loader} alt="Loading..." />
              </div>
            ) : (
              <div className='index-main padding-dashmain'>
                {user_details.role === 'Company'
                  ? (
                    <>
                      <div className='dashboard-main-div1'>
                        <div className='dashboard-main-div1-sub'>

                          <div className='dashboard-name-card'>
                            <div className='dashboard-nmcard-content'>
                              <div className='dashboard-nmcard-view'>
                                <div className='dashboard-nmcard-view1'>
                                  <div className='dashboard-nmandcont'>
                                    <p className='dashboard-nmandcont-p1'>Welcome {user_details.name} !</p>
                                    <p className='dashboard-nmandcont-p2'>To edit your profile information, click the button below to be redirected to the profile edit page.</p>
                                    <div className='dashboard-nmandcont-button'>
                                      <button onClick={navigateedit}>Click</button>

                                    </div>
                                  </div>
                                </div>
                                <div className='dashboard-nmcard-view2'>
                                  <img src={DashboardImages.dashboard_main} alt='img' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='dashboard-card-sml-container'>
                            <div className='dashboard-card-sml'>
                              <div className='dashboard-card-sml-view'>
                                <div className='dashboard-card-sml-main'>
                                  <div className='dashboard-card-sml-cont'>
                                    <h2>{dashboardct.all_count}</h2>
                                    <p className='dashboard-card-sml-cont-p'>All User</p>

                                  </div >
                                  <div className='dashboard-card-sml-imgmain'>
                                    <div className='dashboard-card-sml-img'>
                                      <img src={DashboardImages.usersall} alt='img' />
                                    </div>
                                  </div >
                                </div>

                              </div >
                              <div className='dashboard-card-sml-view'>
                                <div className='dashboard-card-sml-main'>
                                  <div className='dashboard-card-sml-cont'>
                                    <h2>{dashboardct.leave_count}</h2>
                                    <p className='dashboard-card-sml-cont-p'>Leave Request</p>

                                  </div >
                                  <div className='dashboard-card-sml-imgmain'>
                                    <div className='dashboard-card-sml-img'>
                                      <img src={DashboardImages.leave} alt='img' />
                                    </div>
                                  </div >
                                </div>

                              </div >
                            </div>
                            <div className='dashboard-card-sml'>
                              <div className='dashboard-card-sml-view'>
                                <div className='dashboard-card-sml-main'>
                                  <div className='dashboard-card-sml-cont'>
                                    <h2>{dashboardct.active_count}</h2>
                                    <p className='dashboard-card-sml-cont-p'>Active User</p>

                                  </div >
                                  <div className='dashboard-card-sml-imgmain'>
                                    <div className='dashboard-card-sml-img'>
                                      <img src={DashboardImages.activeusers} alt='img' />
                                    </div>
                                  </div >
                                </div>

                              </div >
                              <div className='dashboard-card-sml-view'>
                                <div className='dashboard-card-sml-main'>
                                  <div className='dashboard-card-sml-cont'>
                                    <h2>{dashboardct.deactive_count}</h2>
                                    <p className='dashboard-card-sml-cont-p'>Deactive User</p>

                                  </div >
                                  <div className='dashboard-card-sml-imgmain'>
                                    <div className='dashboard-card-sml-img'>
                                      <img src={DashboardImages.deactiveusers} alt='img' />
                                    </div>
                                  </div >
                                </div>

                              </div >
                            </div>
                          </div>
                          <div className='dashboard-card-leave-reqview'>
                            <div className='dashboard-card-leave-reqview-sub'>
                              <div className='dashboard-card-leave-main'>
                                <div className='dashboard-card-leave-heading'>
                                  <h4>Pending Leaves</h4>
                                  <div className='dashboard-card-leave-button'>
                                    <button onClick={navigateleave}>View</button>
                                  </div>
                                </div>
                                <div className='dashboard-card-leave-table'>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>No of Days</th>
                                        <th>Leave Type</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {dashboardpendinglev.slice(0, 4).map((emp_ped, index) => (
                                        <tr key={index}>
                                          <td>{emp_ped.name}</td>
                                          <td>{emp_ped.total_days}</td>
                                          <td>{emp_ped.leave_type}</td>
                                        </tr>
                                      ))

                                      }

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='dashboard-main-div2'>
                        <div className='dashboard-main-div2-sub'>

                          <div className='dashboard-main-div2-cont'>
                            <div>
                              <div className='calendar-section-main'>
                                <div className='calendar-section'>
                                  <CalendarDashboard />
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className='dashboard-main-div2-cont1'>
                            <div className='dashboard-card-emp-reqview'>
                              <div className='dashboard-card-leave-reqview-sub'>
                                <div className='dashboard-card-leave-main'>
                                  <div className='dashboard-card-leave-heading'>
                                    <h4>New Recruit</h4>
                                    <div className='dashboard-card-leave-button'>
                                      <button onClick={navigateemploye}>View</button>
                                    </div>
                                  </div>
                                  <div className='dashboard-card-leave-table'>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Department</th>
                                          <th>Company</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {dashboardemplnew.slice(0, 3).map((empnew, index) => (
                                          <tr key={index}>
                                            <td>{empnew.name}</td>
                                            <td>{empnew.department}</td>
                                            <td>{empnew.company}</td>

                                          </tr>
                                        ))

                                        }

                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='dashboard-main-div1'>
                        <div className='dashboard-main-div1-sub'>

                          <div className='dashboard-name-card'>
                            <div className='dashboard-nmcard-content'>
                              <div className='dashboard-nmcard-view'>
                                <div className='dashboard-nmcard-view1'>
                                  <div className='dashboard-nmandcont'>
                                    <p className='dashboard-nmandcont-p1'>Welcome {userName} !</p>
                                    <p className='dashboard-nmandcont-p2'>To edit your profile information, click the button below to be redirected to the profile edit page.</p>
                                    <div className='dashboard-nmandcont-button'>
                                      <button onClick={navigateeditstaff}>Click</button>

                                    </div>
                                  </div>
                                </div>
                                <div className='dashboard-nmcard-view2'>
                                  <img src={DashboardImages.dashboard_main} alt='img' />
                                </div>
                              </div>



                            </div>




                          </div>
                          <div className='dashboard-card-sml-container'>
                            <div className='dashboard-card-sml'>
                              <div className='dashboard-card-sml-view'>
                                <div className='dashboard-card-sml-main'>
                                  <div className='dashboard-card-sml-cont'>
                                    {staffdetcount?.doj_time_difference ? (
                                      <h2>{convertToFractionalYears(staffdetcount.doj_time_difference)}</h2>
                                    ) : (
                                      ""
                                    )}                            <p className='dashboard-card-sml-cont-p'>Years of Working</p>

                                  </div >
                                  <div className='dashboard-card-sml-imgmain'>
                                    <div className='dashboard-card-sml-img'>
                                      <img src={DashboardImages.totalworking} alt='img' />
                                    </div>
                                  </div >
                                </div>

                              </div >
                              <div className='dashboard-card-sml-view'>
                                <div className='dashboard-card-sml-main'>
                                  <div className='dashboard-card-sml-cont'>
                                    <h2>{staffdetcount.leave_count}</h2>
                                    <p className='dashboard-card-sml-cont-p'>Total Leave</p>

                                  </div >
                                  <div className='dashboard-card-sml-imgmain'>
                                    <div className='dashboard-card-sml-img'>
                                      <img src={DashboardImages.totalleave} alt='img' />
                                    </div>
                                  </div >
                                </div>

                              </div >
                            </div>
                            <div className='dashboard-card-sml'>
                              <div className='dashboard-card-sml-view'>
                                <div className='dashboard-card-sml-main'>
                                  <div className='dashboard-card-sml-cont'>
                                    <h2>{staffdetcount.salary_count}</h2>
                                    <p className='dashboard-card-sml-cont-p'>Payroll</p>

                                  </div >
                                  <div className='dashboard-card-sml-imgmain'>
                                    <div className='dashboard-card-sml-img'>
                                      <img src={DashboardImages.payroll} alt='img' />
                                    </div>
                                  </div >
                                </div>

                              </div >
                              <div className='dashboard-card-sml-view'>
                                <div className='dashboard-card-sml-main'>
                                  <div className='dashboard-card-sml-cont'>
                                    <h2>{staffdetcount.pending_leave_count}</h2>
                                    <p className='dashboard-card-sml-cont-p'>Pending Leave</p>

                                  </div >
                                  <div className='dashboard-card-sml-imgmain'>
                                    <div className='dashboard-card-sml-img'>
                                      <img src={DashboardImages.pendingleave} alt='img' />
                                    </div>
                                  </div >
                                </div>

                              </div >
                            </div>
                          </div>
                          <div className='dashboard-card-leave-reqview'>
                            <div className='dashboard-card-leave-reqview-sub'>
                              <div className='dashboard-card-leave-main'>
                                <div className='dashboard-card-leave-heading'>
                                  <h4>Pending Leaves</h4>
                                  <div className='dashboard-card-leave-button'>
                                    <button onClick={navigateleavestaff}>View</button>
                                  </div>
                                </div>
                                <div className='dashboard-card-leave-table'>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>No of Days</th>
                                        <th>Leave Type</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {fthpending.slice(0, 3).map((emp_ped, index) => (
                                        <tr key={index}>
                                          <td>{emp_ped.name}</td>
                                          <td>{emp_ped.total_days}</td>
                                          <td>{emp_ped.leave_type}</td>
                                        </tr>
                                      ))

                                      }

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='dashboard-main-div2'>
                        <div className='dashboard-main-div2-sub'>

                          <div className='dashboard-main-div2-cont'>
                            <div>
                              <div className='calendar-section-main'>
                                <div className='calendar-section'>
                                  <CalendarDashboard />
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className='dashboard-main-div2-cont1'>
                            <div className='dashboard-card-emp-reqview'>
                              <div className='dashboard-card-leave-reqview-sub'>
                                <div className='dashboard-card-leave-main'>
                                  <div className='dashboard-card-leave-heading'>
                                    <h4>Recent Payroll</h4>
                                    <div className='dashboard-card-leave-button'>
                                      <button onClick={payrollstaff}>View</button>
                                    </div>
                                  </div>
                                  <div className='dashboard-card-leave-table'>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Month/Year</th>
                                          <th>Salary</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {payrllstaff.slice(0, 4).reverse().map((empnew, index) => (
                                          <tr key={index}>
                                            <td>{empnew.name}</td>
                                            <td>{monthNames[empnew.month]}/ {empnew.year}</td>
                                            <td>{Math.round(empnew.earning_salary)}</td>

                                          </tr>
                                        ))

                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
};
export default Dashboard