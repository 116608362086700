import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const departmentHeadSlice = createSlice({
    name: 'department_head',
    initialState,
    reducers: {
        addDepartmentHead(state, action) {
            state.push(action.payload)

        },
        setDepartmentHead(state, action) {
            return action.payload;
        },
        updatedepartmenthdcomp(state, action) {
            const updatedComponent = action.payload;
            return state.map((component) =>
                component.row_id === updatedComponent.row_id
                    ? { ...component, ...updatedComponent }
                    : component
            )
        },
        deletedepartmenthd(state, action) {
            const componentId = action.payload;
            return state.filter((component) => component.row_id !== componentId);
        }
    }
})

export const { addDepartmentHead, setDepartmentHead, updatedepartmenthdcomp, deletedepartmenthd } = departmentHeadSlice.actions;
export default departmentHeadSlice.reducer;