import CryptoJS from "crypto-js";

export function decryptOTP(encryptedOTP) {
  const secretKey = "django-insecure-j55v^krn%qevhdd0wdu#=gtxz1zzt3^4@@#^$22&$ygn^a3ld^"; // Make sure this matches the backend's secret key

  const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedOTP);

  const iv = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(0, 4), 16);

  const encryptedMessage = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(4));

  const key = CryptoJS.SHA256(secretKey);

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedMessage },
    key,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  const decryptedOTP = decrypted.toString(CryptoJS.enc.Utf8);

  return decryptedOTP;
}
