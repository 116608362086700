import { Route, Routes } from 'react-router-dom';
import Login from './Auth/Login/Login';
import Registeration from './Auth/Registeration/Registeration';
import Companyinfo from './Auth/Registeration/compdetails/companyinfo';
import Index from './Pages/Dashboard/Dashboard'
import { ToastContainer } from 'react-toastify';
import Emppersonalinfo from './Pages/Employees/AddEmployees/Emppersonalinfo/Emppersonalinfo';
import Basicdetails from './Pages/Employees/AddEmployees/Basicdetails/Basicdetails';
import Salarydetails from './Pages/Employees/AddEmployees/Salarydetails/Salarydetails';
import Earnings from './Pages/Salarycomponent/Earnings/Earnings'
import Deductions from './Pages/Salarycomponent/Deductions/Deductions'
import Payment from './Pages/Employees/AddEmployees/Payment/Payment'
import ViewEmployee from './Pages/Employees/Employeeview/ViewEmployee';
import Vieweditdetails from './Pages/Employees/EditEmployee/vieweditdetails/Vieweditdetails';
import Viewsalarydetails from './Pages/Employees/EditEmployee/Viewsalarydetails/Viewsalarydetails';
import Editbasicinfo from './Pages/Employees/EditEmployee/Editbasicinfo/Editbasicinfo';
import Editpersonalinfo from './Pages/Employees/EditEmployee/Editpersonalinfo/Editpersonalinfo';
import Editpaymentinfo from './Pages/Employees/EditEmployee/Editpaymentdetails/Editpaymentinfo';
import Editsalarydetails from './Pages/Employees/EditEmployee/Editsalarydetails/Editsalarydetails';
import Viewleave from './Pages/Leave/Viewleave/Viewleave';
import StaffLeave from './Pages/Leave/StaffLeave/StaffLeave';
import Viewattendance from './Pages/Attendance/Viewattendance/Viewattendance';
import Viewpayroll from './Pages/Payroll/Viewpayroll/Viewpayroll';
import Viewsalaryslip from './Pages/Payroll/Viewsalaryslip/Viewsalaryslip';
import Vieworgprofile from './Pages/Settings/Organisation_profile/Vieworganisation_profile/Vieworg_profile';
import Home from './Website/Home';
import Viewdepartment from './Pages/Settings/Department/Viewdepartment';
import Viewdesignation from './Pages/Settings/Designation/Viewdesignation';
import Viewdepartmenthd from './Pages/Settings/Departmenthead/viewdepartmenthead';
import Viewdepartmenttl from './Pages/Settings/Departmenttl/viewdepartmenttl';
import Changepassword from './Pages/Settings/Changepassword/Changepassword';
import Leavecompo from './Pages/Leavecomponent/Leavecomponent';
import { Provider } from 'react-redux';
import { Store, persistor } from './Store';
import { PersistGate } from 'redux-persist/integration/react';
import Profile from './Pages/ManageAccount/Profile';
import Privacypage from './Pages/Settings/Privacy/privacy';
import Feedbackpage from './Pages/Settings/Feedback/Feedback';
import HolidayView from './Pages/Settings/Holiday/Holiday';
import PrivateRoute from './PrivateRoute';
import ViewStaffAttendance from './Pages/Attendance/Viewattendance/ViewStaffAttendance';
import Viewusersalary from './Pages/ManageAccount/UserProfile/usersalary';
import Edituserpersonalinfo from './Pages/ManageAccount/UserProfile/Edituserprofile/Edituserpersonalinfo';
import Staffprofile from './Pages/ManageAccount/UserProfile/Userprofile';
import Edituserbasicinfo from './Pages/ManageAccount/UserProfile/Edituserprofile/Edituserbasicdetails';
import ViewStaffPayroll from './Pages/Payroll/Viewpayroll/ViewStaffPayroll';
import Forgetpass from './Auth/Forgetpass/Forgetpass';
import IncompleteEmployee from './Pages/Employees/Employeeview/Pendingemp';
import { HashRouter } from "react-router-dom";

function App() {

  return (

    <HashRouter>
      <ToastContainer />
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<Forgetpass />} />
            <Route path="/signup" element={<Registeration />} />
            <Route path='/compdetails' element={<Companyinfo />} />
            <Route path='/dashboard'
              element={<PrivateRoute element={<Index />}
                roles={["Company", "staff"]} />} />
            <Route path='/employee'
              element={<PrivateRoute element={<ViewEmployee />}
                roles={["Company"]} />} />
            <Route path='/incompemployee'
              element={<PrivateRoute element={<IncompleteEmployee />}
                roles={["Company"]} />} />
            <Route path='/addemployee'
              element={<PrivateRoute element={<Emppersonalinfo />}
                roles={["Company"]} />}
            />
            <Route path='/basicdetails'
              element={<PrivateRoute element={<Basicdetails />}
                roles={["Company"]} />}
            />
            <Route path='/salarydetails'
              element={<PrivateRoute element={<Salarydetails />}
                roles={["Company"]} />}
            />
            <Route path='/addearningcomp'
              element={<PrivateRoute element={<Earnings />}
                roles={["Company"]} />}
            />
            <Route path='/adddeductioncomp'
              element={<PrivateRoute element={<Deductions />}
                roles={["Company"]} />}
            />
            <Route path='/addpayment'
              element={<PrivateRoute element={<Payment />}
                roles={["Company"]} />}
            />
            <Route path='/vieweditdetails'
              element={<PrivateRoute element={<Vieweditdetails />}
                roles={["Company"]} />}
            />
            <Route path='/editbasicinfo'
              element={<PrivateRoute element={<Editbasicinfo />}
                roles={["Company"]} />}
            />
            <Route path='/viewsalarydetails'
              element={<PrivateRoute element={<Viewsalarydetails />}
                roles={["Company"]} />}
            />
            <Route path='/editpersonalinfo'
              element={<PrivateRoute element={<Editpersonalinfo />}
                roles={["Company"]} />}
            />
            <Route path='/editpaymentinfo'
              element={<PrivateRoute element={<Editpaymentinfo />}
                roles={["Company"]} />}
            />
            <Route path='/editsalarydetails'
              element={<PrivateRoute element={<Editsalarydetails />}
                roles={["Company"]} />}
            />
            <Route path='/viewleave'
              element={<PrivateRoute element={<Viewleave />}
                roles={["Company"]} />}
            />
            <Route path='/staffLeave'
              element={<PrivateRoute element={<StaffLeave />}
                roles={["staff"]} />}
            />
            <Route path='/staffprofile'
              element={<PrivateRoute element={<Staffprofile />}
                roles={["staff"]} />}
            />
            <Route path='/staffsalary'
              element={<PrivateRoute element={<Viewusersalary />}
                roles={["staff"]} />}
            />
            <Route path='/viewattendance'
              element={<PrivateRoute element={<Viewattendance />}
                roles={["Company"]} />}
            />
            <Route path='/viewpayroll'
              element={<PrivateRoute element={<Viewpayroll />}
                roles={["Company"]} />}
            />
            <Route path='/viewsalaryslip'
              element={<PrivateRoute element={<Viewsalaryslip />}
                roles={["Company"]} />}
            />
            <Route path='/vieworgprofile'
              element={<PrivateRoute element={<Vieworgprofile />}
                roles={["Company"]} />}
            />
            <Route path='/viewdep'
              element={<PrivateRoute element={<Viewdepartment />}
                roles={["Company"]} />}
            />
            <Route path='/viewdesignation'
              element={<PrivateRoute element={<Viewdesignation />}
                roles={["Company"]} />}
            />
            <Route path='/viewdephead'
              element={<PrivateRoute element={<Viewdepartmenthd />}
                roles={["Company"]} />}
            />
            <Route path='/viewdeptl'
              element={<PrivateRoute element={<Viewdepartmenttl />}
                roles={["Company"]} />}
            />
            <Route path='/changepass'
              element={<PrivateRoute element={<Changepassword />}
                roles={["Company", "staff"]} />}
            />
            <Route path='/viewleavecomp'
              element={<PrivateRoute element={<Leavecompo />}
                roles={["Company"]} />}
            />
            <Route path='/viewprofile'
              element={<PrivateRoute element={<Profile />}
                roles={["Company"]} />}
            />

            <Route path='/privacy' element={<Privacypage />} />
            <Route path='/feedback' element={<Feedbackpage />} />
            <Route path='/holiday'
              element={<PrivateRoute element={<HolidayView />}
                roles={["Company"]} />}
            />
            <Route path='/viewstaffattendance'
              element={<PrivateRoute element={<ViewStaffAttendance />}
                roles={["staff"]} />}
            />
            <Route path='/editstaffpersonalinfo'
              element={<PrivateRoute element={<Edituserpersonalinfo />}
                roles={["staff"]} />}
            />
            <Route path='/editstaffbasic'
              element={<PrivateRoute element={<Edituserbasicinfo />}
                roles={["staff"]} />}
            />
            <Route path='/viewstaffpayroll'
              element={<PrivateRoute element={<ViewStaffPayroll />}
                roles={["staff"]} />}
            />
          </Routes>
        </PersistGate>
      </Provider>
    </HashRouter>
  );
}

export default App;