const manageimg={
    manageaccount_imag:"./Images/Manageaccount/userprofile.png",
    mobilenumber:"./Images/Manageaccount/phone.png",
    addressline1:"./Images/Manageaccount/location.png",
    addressline2:"./Images/Manageaccount/map.png",
    statecity   :"./Images/Manageaccount/state.png",
    pincode     :"./Images/Manageaccount/mail.png"




}
export default manageimg;