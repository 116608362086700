import React, { useState } from 'react';
import uploadlogo from '../../../Images/Register/logoupload';
import api_backend from '../../../Config/api';
import { toast } from 'react-toastify';
import { AddCompanyDetailsApi } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import { Store } from '../../../Store';
import { addCompany } from '../../../Slice/companySlice'

const Addorgprofile = ({ show, close, sendDataToParent }) => {
  const user_details = getUserDetails();
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setleading] = useState(false)
  const [addorgprofile, setorpprofile] = useState({
    company_name: '',
    company_email: '',
    address_line1: '',
    address_line2: '',
    company_phone_num: '',
    company_logo: '',
    city: '',
    state: '',
    pincode: '',
    branch_type: 'Branch',
    user: user_details.id,

  })
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (name === "company_phone_num") {
      // Only allow numbers for the company phone number
      const numericValue = value.replace(/\D/g, "");
      setorpprofile((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else if (name === "company_name") {
      // Only allow alphabets for the company name
      const stringValue = value.replace(/[^a-zA-Z\s]/g, "");
      setorpprofile((prevData) => ({
        ...prevData,
        [name]: stringValue,
      }));
    } else if (type === "file") {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        setorpprofile((state) => ({
          ...state,
          [name]: file,
        }));
        setSelectedImage(URL.createObjectURL(file));

      } else {
        setSelectedImage("")
      }
    } else {
      setorpprofile((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };



  const handleSubmit = async () => {
    const fieldData = [
      {
        name: "company_name",
        type: "input",
        err: "Company Name",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "company_email",
        type: "input",
        err: "Company Email",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
          (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), // Correct email regex
        ],
      },
      {
        name: "company_phone_num",
        type: "input",
        err: "Company Phone",
        conditions: [
          (value) => typeof value === "string" && value.trim() !== "",
          (value) => /^\d{10}$|^\d{13}$/.test(value), // Ensure 10 or 13 digits only
        ],
      },
      {
        name: "address_line1",
        type: "input",
        err: "Address Line 1",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "address_line2",
        type: "input",
        err: "Address Line 2",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "city",
        type: "input",
        err: "City",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "state",
        type: "input",
        err: "State",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "pincode",
        type: "input",
        err: "Pincode",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
    ];

    const fieldOrder = [
      "company_name",
      "company_phone_num",

      "company_email",
      "address_line1",
      "address_line2",
      "city",
      "state",
      "pincode",
    ];

    let newErrors = {};
    for (const fieldName of fieldOrder) {
      const fieldInfo = fieldData.find((field) => field.name === fieldName);
      if (!fieldInfo) continue;

      const value = addorgprofile[fieldName]; // Get the actual value from state
      const isFieldValid = fieldInfo.conditions.every((condition) =>
        condition(value)
      );

      if (!isFieldValid) {
        newErrors[fieldName] = `${fieldInfo.err} is required or invalid.`;
        break; // Stop at the first validation error
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        setleading(true);
        const formData = new FormData();
        for (let key in addorgprofile) {
          formData.append(key, addorgprofile[key]);
        }
        const response = await AddCompanyDetailsApi(formData);
        if (response) {
          sendDataToParent(response.data.company_details);
          Store.dispatch(addCompany(response.data.company_details));


          setorpprofile((prev) => ({
            ...prev,
            company_name: '',
            company_email: '',
            address_line1: '',
            address_line2: '',
            company_phone_num: '',
            company_logo: '',
            city: '',
            state: '',
            pincode: '',
            branch_type: 'Branch',
            user: user_details.id,

          }));
          setSelectedImage(null); // Clear the selected image

          close();
          toast.success("Organization Profile Added Successfully!", {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: "custom_toast",
          });
        }
      } catch (error) {

        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key];
            }
          }

          setErrors(newErrors);
        } else {
          toast.error('organization Registration failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'

          });

          setErrors({
            response_error: 'An unexpected error occurred. Please try again later.',
          });
        }


      } finally {
        setleading(false);
      }
    }
  };


  if (!show) return null;

  return (
    <div className='editorg-section'>
      <div className='editorg-container'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>

        <div className='editorg-container-sub'>
          <div className='editorg-heading'>
            Add Organization Profile
          </div>
          <div className='editorg-main'>


            <div className='addleave-strightline'></div>

            <div className='editorg-form-fields'>

              <label htmlFor="company-logo" className='companyinfo-label-view'>Company Logo</label>
              <div className='companyinfo-imgupload'>
                <div className='companyinfo-imgupload-sub' onClick={handleInputChange}>
                  <div>
                    {selectedImage ? (
                      <img
                        id="preview-image"
                        name='company-logo'
                        className='companyinfo-img-field-view'
                        src={selectedImage}
                        alt="Uploaded"
                      />
                    ) : addorgprofile.company_logo ? (

                      (<img
                        id="preview-image"
                        className='companyinfo-img-field-view'
                        src={`${api_backend.BACKEND_API}${addorgprofile.company_logo}`}
                        alt="Uploaded"
                      />)
                    ) :

                      (
                        <img
                          id="preview-image"
                          className='companyinfo-img-field'
                          src={uploadlogo.upload_logo}
                          alt="Uploaded"
                        />
                      )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    name='company_logo'
                    onChange={handleInputChange}
                    style={{ width: "100%", height: "100%", position: "absolute", opacity: "-0.5", fontSize: "1px" }}
                  />
                </div>

              </div>
              {
                errors.company_logo && (<div className='error-message-view'>{errors.company_logo}</div>)
              }
              <br />
              <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                  <label htmlFor='companyName'>Company Name</label>
                  <input
                    id='companyName'
                    name='company_name'
                    type='text'
                    value={addorgprofile.company_name}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.company_name && (<div className='error-message-view'>{errors.company_name}</div>)
                  }
                </div>

                <div className='companyinfo-input-group'>
                  <label htmlFor='companyPhone'>Company Phone</label>
                  <input
                    id='companyPhone'
                    name='company_phone_num'
                    type='text'
                    value={addorgprofile.company_phone_num}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.company_phone_num && (<div className='error-message-view'>{errors.company_phone_num}</div>)
                  }
                </div>
              </div>
              <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                  <label htmlFor='companyEmail'>Company Email</label>
                  <input
                    id='companyEmail'
                    name='company_email'
                    value={addorgprofile.company_email}
                    type='text'
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.company_email && (<div className='error-message-view'>{errors.company_email}</div>)
                  }
                </div>

              </div>
              <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                  <label htmlFor='addressLine1'>Address Line 1</label>
                  <input
                    id='addressLine1'
                    name='address_line1'
                    type='text'
                    value={addorgprofile.address_line1}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.address_line1 && (<div className='error-message-view'>{errors.address_line1}</div>)
                  }
                </div>
                <div className='companyinfo-input-group'>
                  <label htmlFor='addressLine2'>Address Line 2</label>
                  <input
                    id='addressLine2'
                    name='address_line2'
                    type='text'
                    value={addorgprofile.address_line2}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.address_line2 && (<div className='error-message-view'>{errors.address_line2}</div>)
                  }
                </div>

              </div>
              <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                  <label htmlFor='city'>City</label>
                  <input
                    id='city'
                    name='city'
                    type='text'
                    value={addorgprofile.city}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.city && (<div className='error-message-view'>{errors.city}</div>)
                  }
                </div>
                <div className='companyinfo-input-group'>
                  <label htmlFor='state'>State</label>
                  <input
                    id='state'
                    name='state'
                    type='text'
                    value={addorgprofile.state}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.state && (<div className='error-message-view'>{errors.state}</div>)
                  }
                </div>
                <div className='companyinfo-input-group'>
                  <label htmlFor='pincode'>Pincode</label>
                  <input
                    id='pincode'
                    name='pincode'
                    type='text'
                    value={addorgprofile.pincode}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.pincode && (<div className='error-message-view'>{errors.pincode}</div>)
                  }
                </div>
              </div>
              {
                errors.response_error && (
                  <div className='error-message-view salry-comp-err'>
                    {errors.response_error.component}
                  </div>
                )
              }
              <div className='companyinfo-div-btn'>
                <button type='button' onClick={handleSubmit} disabled={loading}> {loading ? <span className='spinner'></span> : 'Submit'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addorgprofile;
