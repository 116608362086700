import React, { useState } from 'react';

import './Uploadexcel.css';
import { addExcelToDatabaseApi } from '../../../Config/api';
import { getCompanyDetails, getUserDetails } from '../../../Services/Storage';
import { toast } from 'react-toastify';

const Uploadexcel = ({ show, close }) => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const user_details = getUserDetails();
  const company_details = getCompanyDetails();
  const [errors, setErrors] = useState({});
  const [loader, setloader] = useState(false)

  if (!show) return null

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    } else {
      setFile(null);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    } else {
    }
  };

  const handleUpload = async () => {
    if (file) {
      const data = {
        "attendance_file": file,
        "register": user_details.id,
        "company": company_details.company_id,
      }
      try {
        setloader(true)
        const response = await addExcelToDatabaseApi(data);
        if (response) {
          setFile(null);  // Clear the file input

          close();
          toast.success('Attendance Added Sucessfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          })
        }
      }
      catch (error) {
        const newErrors = {
          response_error: [],
        };
        for (const key in error.response.data.data) {
          if (error.response.data.data.hasOwnProperty(key)) {
            newErrors.response_error.push(`${error.response.data.data[key]}`);
          }
        }
        toast.error("Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
        setErrors(newErrors);
      } finally {
        setloader(false)
      }
    } else {
    }
  };
  return (
    <div className='uploadexcel-section'>
      <div className='uploadexcel-container'>

        <div className='uploadexcel-container-sub'>
          <div className='uploadexcel-content'>

            <span className='uploadexcel-close' onClick={close}>&times;</span>
            <p>Upload Excel</p>
            <div
              className={`uploadexcel-upload-area ${dragging ? 'dragging' : ''}`}
              onDragOver={(e) => {
                e.preventDefault();
                setDragging(true);
              }}
              onDragLeave={() => setDragging(false)}
              onDrop={handleDrop}
            >
              {file ? <p>Selected file: {file.name}</p> : <p>Drag and drop your Excel file here, or click to select</p>}
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="file-upload"
              />
              <label htmlFor="file-upload" className="uploadexcel-upload-button">Browse Files</label>
            </div>
            {errors.response_error && (
              <div className="registeration-error-view">{errors.response_error}</div>
            )}
            <button className='uploadexcel-upload-btn' onClick={handleUpload} disabled={loader}>

              {loader ?
                <span className='spinner'></span>
                : "Upload"
              }

            </button>

          </div>

        </div>
      </div>
    </div>
  )
}

export default Uploadexcel