import React, { useState } from 'react'
import Header from '../../../Header/Header'
import "../../Style/Employeeform.css";
import { useNavigate } from 'react-router-dom'
import '../../Style/Addemp.css'
import Sidebar from '../../../Sidebar/Sidebar'
import { Registration } from '../../../../Config/api'
import { toast } from 'react-toastify';
import { getUserDetails } from '../../../../Services/Storage';
import Title from '../../../../JsonData/Tittle';

const Emppersonalinfo = () => {
  const navigate = useNavigate();
  const user_details = getUserDetails();
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);

  const generateRandomPassword = () => {
    return String(Math.floor(10000 + Math.random() * 90000)); // Converts the 5-digit number to a string
  };
  const [personalInfo, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address_line1: '',
    address_line2: '',
    city: '',
    password: generateRandomPassword(),
    state: '',
    pincode: '',
    role: "staff",
    user_mr_ms: 'Mr',
    user_id: user_details.id,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handlekeypress = (e) => {
    const restrictedpattern = /[0-9!@#$%^&*(),.?":{}|<>+_]/;
    if ((e.target.name === 'name' || e.target.name === 'state' || e.target.name === 'city') && restrictedpattern.test(e.key)) {
      e.preventDefault();
    }
  }
  const handlekeynumber = (e) => {
    const restricpattern = /[^0-9]/g;
    if ((e.target.name === 'phone' || e.target.name === 'pincode') && restricpattern.test(e.key)) {
      e.preventDefault();

    }
  }

  const handleSubmit = async () => {
    let newErrors = {};
    if (personalInfo.name.trim() === "") {
      newErrors.name = "Name is Required";
    }
    if (personalInfo.email.trim() === "") {
      newErrors.email = "Email is Required";
    } else if (!/^\S+@\S+\.\S+$/.test(personalInfo.email)) {
      newErrors.email = 'Invalid email format';
    } if (personalInfo.phone.trim() === "") {
      newErrors.phone = "Phone Number is Required";
    } else if (!/^\d{10}$/.test(personalInfo.phone)) {
      newErrors.phone = "Phone Number must be exactly 10 digits";
    }

    if (personalInfo.address_line1.trim() === "") {
      newErrors.address_line1 = "Address Line1 is Required"
    }
    if (personalInfo.address_line2.trim() === "") {
      newErrors.address_line2 = "Address Line2 is Required";
    }
    if (personalInfo.city.trim() === "") {
      newErrors.city = "City is Required"
    }
    if (personalInfo.state.trim() === "") {
      newErrors.state = "State is Required"
    }
    if (personalInfo.pincode.trim() === "") {
      newErrors.pincode = "Pincode is Required";
    } else if (!/^\d{6}$/.test(personalInfo.pincode.trim())) {
      newErrors.pincode = "Pincode must be exactly 6 digits";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true)
        const response = await Registration(personalInfo);
        toast.success('Personal Info Added successfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast'
        });
        if (response.status === 200) {
          navigate('/basicdetails', { state: { name: response.user_details.name, user_id: response.user_details.id } });
        }
      } catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key]; // Assign the specific error message to the respective field
            }
          }

          setErrors(newErrors);
        } else {
          toast.error('Employee Registration failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'

          });

          // Handle generic errors
          setErrors({
            response_error: 'An unexpected error occurred. Please try again later.',
          });
        }
      } finally {
        setloading(false)
      }
    }
  };


  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='addemp-subheader'>
                <div className='addemp-subheader-sub'>
                  <div className='addemp-subheader1'>
                    <p>Add Personal Info</p>
                  </div>
                  <div className='addemp-subheader2'>


                    <div className="addemp-stepper">
                      <div className="addemp-step-indicator">
                        <div className="addemp-step addemp-selected">
                          Personal Info
                        </div>
                        <div className='addemp-step'>Basic Details</div>
                        <div className='addemp-step'>Salary Details</div>
                        <div className='addemp-step'>Payment</div>
                      </div>


                    </div>

                  </div>

                </div>
              </div>
              <div className='addemp-line'></div>
              <div className='addemp-maincontent-sub'>
                <div className='addemp-form form-padding'>
                  <div className='addemp-form-sub'>
                    <div className='addemp-form-sub-main'>
                      <div className='emppersonalinfo-input-row'>

                        <div className='emppersonalinfo-input-group'>
                          <label> Name <span className='emppersonalinfo-mandatory '>*</span></label>
                          <div className='tittle-view-in'>
                            <select className='tittle-view-field' name='user_mr_ms' onChange={handleInputChange}>

                              {Title.title.map((tittle, index) => (
                                <option key={index}>
                                  {tittle}

                                </option>
                              )

                              )

                              };

                            </select>
                            <input className='emppersonalinfo-input-field tittle-view' onKeyPress={handlekeypress} placeholder='Enter name' value={personalInfo.name} name='name' onChange={handleInputChange} type='text' />

                          </div>
                          {errors.name && (<div className='error-message-view'>{errors.name}</div>)}

                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label> Phone  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field' onKeyPress={handlekeynumber} name='phone' value={personalInfo.phone} onChange={handleInputChange} placeholder='Enter mobile no' type='text' />
                          {errors.phone && (<div className='error-message-view'>{errors.phone}</div>)}
                        </div>

                      </div>
                      <div className='emppersonalinfo-input-col'>
                        <div className='emppersonalinfo-col-view'>
                          <label>Email <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-inputfield-col' name='email' value={personalInfo.email} onChange={handleInputChange} placeholder='abc@xyz.com' type='text' />
                        </div>
                        {errors.email && (<div className='error-message-view'>{errors.email}</div>)}
                      </div>
                      <div className='emppersonalinfo-input-col'>
                        <div className='emppersonalinfo-col-view'>
                          <label>Address Line1 <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-inputfield-col' name='address_line1' value={personalInfo.address_line1} onChange={handleInputChange} placeholder='Enter address line 1' type='text' />
                        </div>
                        {errors.address_line1 && (<div className='error-message-view'>{errors.address_line1}</div>)}
                      </div>
                      <div className='emppersonalinfo-input-col'>
                        <div className='emppersonalinfo-col-view'>
                          <label>Address Line2 <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-inputfield-col' name='address_line2' value={personalInfo.address_line2} onChange={handleInputChange} placeholder='Enter address line 2' type='text' />
                        </div>
                        {errors.address_line2 && (<div className='error-message-view'>{errors.address_line2}</div>)}
                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label> City <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-input-field3' onKeyPress={handlekeypress} name='city' value={personalInfo.city} onChange={handleInputChange} placeholder='Enter city' type='text' />
                          {errors.city && (<div className='error-message-view'>{errors.city}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label> State <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field3' name='state' onKeyPress={handlekeypress} value={personalInfo.state} onChange={handleInputChange} placeholder='Enter State' type='text' />
                          {errors.state && (<div className='error-message-view'>{errors.state}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label> Pincode <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field3' name='pincode' onKeyPress={handlekeynumber} value={personalInfo.pincode} onChange={handleInputChange} placeholder='Enter pincode' type='text' />
                          {errors.pincode && (<div className='error-message-view'>{errors.pincode}</div>)}
                        </div>
                      </div>
                      {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}

                      <div className='emppersonalinfo-submitbutton'>
                        <button onClick={handleSubmit} disabled={loading}>{loading ? <span className='spinner'></span> : 'Submit'}</button>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Emppersonalinfo